import { Amplify, Storage } from 'aws-amplify';
import awsConfigure from './aws-exports'

export function configureAmplify() {

    Amplify.configure(awsConfigure)

}


