import React from 'react'
import { formatDate, TBP } from '../helper';
import LabelInfoPair from './LabelInfoPair'

const ReceivingCmpnyInfo = (props) => {

    const { reviewData } = props;


    return (
        <div className="cc-card-body">

            <LabelInfoPair
                label='Company Code'
                information={reviewData?.receivingCompanyCode}
            />

            {   
                reviewData?.recvBusinessPlace &&
                    <LabelInfoPair
                        label='Business Place'
                        information={reviewData?.recvBusinessPlace}
                    />
            }

            <LabelInfoPair
                label='Invoice Date'
                information={reviewData?.invoiceDate ? formatDate(reviewData?.invoiceDate) : TBP }
                note={reviewData?.invoiceDate ? false : true}
            />

            <div className='d-flex'>
                <LabelInfoPair
                    label='Posting Date'
                    information={reviewData?.postingDate ? formatDate(reviewData?.postingDate) : TBP}
                    note={reviewData?.postingDate ? false : true}
                />
                
                {
                    reviewData?.postingDate &&
                    <>
                        <LabelInfoPair
                            label='Period'
                            information={reviewData?.ap_posting_period}
                            style={{marginLeft:"15px"}}
                        />
                        
                        <LabelInfoPair
                            label='Year'
                            information={reviewData?.ap_posting_year}
                            style={{marginLeft:"10px"}}
                        />
                    </>
                }
                
            </div>

            <LabelInfoPair
                label='AP Document'
                information={reviewData?.apSAPDocNo ? reviewData?.apSAPDocNo : TBP }
                note={reviewData?.apSAPDocNo ? false : true}
            />

            <LabelInfoPair
                label='AP Reference'
                information={reviewData?.arSAPDocNo ? reviewData?.arSAPDocNo : TBP }
                note={reviewData?.arSAPDocNo ? false : true}
            />

            <LabelInfoPair
                label='AP Requestor'
                information={reviewData?.apRequestor}
            />

        </div>
    )
}

export default ReceivingCmpnyInfo