import { Table } from 'antd';
import React from 'react'
import { getArApColumns } from '../columns'
import Heading from '../HOC/Heading';
import HorizontalLine from '../HOC/HorizontalLine';
import { getPaginationOptions } from '../helper';

const APInfo = (props) => {

    const { reviewData } = props;

    const apColumns = getArApColumns(reviewData?.currency, props?.currencyFormat);
    return (
        <div style={{marginTop:"30px"}}>
            <Heading
                heading="AP Information"
            />

            <HorizontalLine />

            <Table
                id="ap-table"
                size="small"
                className='apTable'
                style={{ width: "100%" }}
                // columns={screenSize > 767 ? orderItemsColumns : orderMobileItemsColumns}
                columns={apColumns}
                dataSource={reviewData?.apLineItems}
                rowKey='item'
                bordered
                pagination={getPaginationOptions(reviewData?.apLineItems?.length, 10)}
                scroll={{ x: 1200 }}
            />
        </div>
    )
}

export default APInfo