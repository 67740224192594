import React from 'react'

const Heading = (props) => {
  return (
      <div className='cc-heading-icons-cnt' style={props.style}>
      <div className='heading-1'>{props.heading}</div>
        {props.children}
      </div>
      
  )
}

export default Heading