import { message, Table } from 'antd';
import React from 'react'
import { useParams } from 'react-router-dom';
import { getSupportingDocColumns } from '../columns';
import { getDownloadPresignedUrl } from '../helper';
import Heading from '../HOC/Heading'
import HorizontalLine from '../HOC/HorizontalLine'

const SupportingDocsList = (props) => {

    const { reviewData } = props;

    const { formID } = useParams();

    const [messageApi, contextHolder] = message.useMessage();

    const supportingDocColumns = [
        {
            title: 'Documents',
            dataIndex: 'fileName',
            key: 'fileName',
            width: '30%',
            className: 'doc-column',
            render: (text, record) => {
                return <span className='cc-link' onClick={() => { getDownloadPresignedUrl(formID,text, messageApi) }}>{text}</span>
            }
        },
        ...getSupportingDocColumns()
    ]

    return (
        <>
        {contextHolder}
            {
                reviewData?.attachments?.length > 0 &&
                <div className='supporting-doc-list-cnt'>
                    <Heading
                        heading='Supporting Documents'
                    />
                    <HorizontalLine 
                        className = 'supporting-doc-line'
                    />


                    <Table
                        id="supportingDoc-table"
                        size="small"
                        className='supportingDocTable'
                        columns={supportingDocColumns}
                        dataSource={reviewData?.attachments}
                        rowKey='fileName'
                        pagination={false}
                        bordered
                    />

                </div>
            }
        </>
    )
}

export default SupportingDocsList