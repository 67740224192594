import { Form, Modal, Input } from 'antd'
import React, { useState } from 'react'
import Heading from '../HOC/Heading'

const { TextArea } = Input;

const AddComments = (props) => {

    const [additionalComments, setAdditionalComments] = useState(null);

    const handleAdditionalComments = (e)=>{
        setAdditionalComments(e.target.value)
    }

    const handleModalSubmit = ()=>{
        props.onSubmit(additionalComments.trim());
        props.closeComment();
    }

    return (
        <div>
            <Modal
                className='cc-comment-modal'
                title={<Heading heading={'Addition Infomation'} />}
                top
                open={props?.openComment}
                okText={'Save Comments'}
                cancelButtonProps={{ "className": "ortho-btn" }}
                okButtonProps={{ "className": "ortho-btn" }}
                onOk={handleModalSubmit}
                onCancel={() => props.closeComment()}
                width={500}
            >

                <Form.Item
                    label="Add comments"
                    name="addComments"
                >
                    <TextArea
                        value={additionalComments}
                        onChange={handleAdditionalComments}
                    />
                </Form.Item>

            </Modal>
        </div>
    )
}

export default AddComments