import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Form, Input, Select, Button, InputNumber, message, Tooltip } from 'antd';
import { API } from 'aws-amplify';
import { InfoCircleOutlined } from '@ant-design/icons'

import Heading from '../HOC/Heading';
import { addPaddingToNum, checkRequstTypeAndAddPostingKey, descSeparator, getGeneralPostingKeys, isPostingKeyExist, validatePostingKey } from '../helper';
import { getCommXchargeDKNA1, getCommXchargeDLFA1, listCommXchargeDKnb1S, listCommXchargeDLFB1S, listCommXchargeDSkb1S } from '../../graphql/queries';
import ContextData from '../context/ContextData';



const TableInfoModal = (props) => {
    console.log("modal props", props, props.editItem);

    const modalSubmitBtnRef = useRef();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const contextValue = useContext(ContextData);
    const { UOMOption, productOption, extMatGroupOption, marsTP } = contextValue;
    console.log("contextValue", contextValue);


    const [postingKey, setPostingKey] = useState('');
    const [amount, setAmount] = useState('');

    const [accountOption, setAccountOption] = useState(null);
    // const [taxCodeOption, setTaxCodeOption] = useState(null);
    // const [costCenterOption, setCostCenterOption] = useState(null);
    // const [profitCenterOption, setProfitCenterOption] = useState(null);
    // const [orderNumOption, setOrderNumOption] = useState(null);

    // const [costCenterData, setCostCenterData] = useState(null);

    const amountRef = useRef();

    const [taxCodeOption, costCenterOption, costCenterData, profitCenterOption, orderNumOption] = props.fieldOptions;

    const emptyElement = { value: null, lable: '' }

    useEffect(() => {
        if (props.editItem !== null) {
            form.setFieldsValue(props.editItem);
            getAccountNumbers(form.getFieldValue('postingKey'));

            // set whichever neccessary

            setAmount(props.editItem?.amount);
            setPostingKey(props.editItem?.postingKey);

        }

    }, [form, props.editItem]);






    const postingKeyOptions = [
        ...(
            // !isPostingKeyExist(props?.lineItems) ?
            !validatePostingKey(props?.lineItems, props?.requestType, props?.currentUser) ?
                (
                    props?.requestType ? checkRequstTypeAndAddPostingKey(props?.requestType, props?.currentUser) : []
                )
                :
                []

        ),

        ...getGeneralPostingKeys()
    ]

    const validateTradingPartner = () => {

        const error = {}
        let tp = form.getFieldValue('tradingPartner');
        tp = tp && tp.trim();

        // checks whether trading partner is empty or not 
        const IsTpNull = (type) => {
            if (tp === '' || tp === ' ' || tp === null || tp === undefined) {
                Modal.error({
                    className: 'info-modal',
                    title: (
                        <>
                            <h3>Validation Error:</h3>
                            <ul>
                                <li>{type} is not an IC {type}. Trading Partner is Missing</li>
                            </ul>
                        </>
                    ),
                    okButtonProps: { "className": "modal-btn" }
                })
                return false
            } else {
                return true
            }
        }

        // checks trading partner accr to AR or AP
        const IsTpMissing = () => {
            if (props.currentUser === 'ar') {
                if (postingKey === '01' || postingKey === '11') {
                    return IsTpNull('Customer');
                } else {
                    return true
                }

            } else if (props.currentUser === 'ap') {
                if (postingKey === '21' || postingKey === '31') {
                    return IsTpNull('Vendor');
                } else {
                    return true
                }
            }
        }

        // checks whether tp matches sending / receving cc 
        const IsTpMatchCC = (headerCC, CC, userType, cmpType) => {
            if (headerCC !== CC) {
                Modal.error({
                    className: 'info-modal',
                    title: (
                        <>
                            <h3>Validation Error:</h3>
                            <ul>
                                <li>IC {userType} does not belong to {cmpType} Company Code</li>
                            </ul>
                        </>
                    ),
                    okButtonProps: { "className": "modal-btn" }
                })
                return false
            } else {
                return true
            }
        }

        // checks whether tp matches sending / receving cc according to AR and AP
        const CheckTP_CC = () => {
            if (tp) {
                const isTP = tp.substring(0, 2);
                if (isTP === 'TP') {
                    const cmpnyCd = tp.replace('TP', '');
                    console.log("cmpnyCd", cmpnyCd);
                    if (props.currentUser === 'ar') {
                        //this check if the company code belongs to specific cmpny code in the static table
                        if (Object.keys(marsTP).includes(props?.receivingCC)) {
                            return IsTpMatchCC(marsTP[props?.receivingCC], cmpnyCd, 'Customer', 'Receiving');
                        } else {
                            return IsTpMatchCC(props?.receivingCC, cmpnyCd, 'Customer', 'Receiving');
                        }

                    } else if (props.currentUser === 'ap') {
                        //this check if the company code belongs to specific cmpny code in the static table
                        if (Object.keys(marsTP).includes(props?.sendingCC)) {
                            return IsTpMatchCC(marsTP[props?.sendingCC], cmpnyCd, 'Vendor', 'Sending');
                        } else {
                            return IsTpMatchCC(props?.sendingCC, cmpnyCd, 'Vendor', 'Sending');
                        }

                    }

                } else {
                    const newTP = String(parseInt(tp));
                    if (props.currentUser === 'ar') {
                        //this check if the company code belongs to specific cmpny code in the static table
                        if (Object.keys(marsTP).includes(props?.receivingCC)) {
                            return IsTpMatchCC(marsTP[props?.receivingCC], newTP, 'Customer', 'Receiving');
                        } else {
                            return IsTpMatchCC(props?.receivingCC, newTP, 'Customer', 'Receiving');
                        }

                    } else if (props.currentUser === 'ap') {
                        //this check if the company code belongs to specific cmpny code in the static table
                        if (Object.keys(marsTP).includes(props?.sendingCC)) {
                            return IsTpMatchCC(marsTP[props?.sendingCC], newTP, 'Vendor', 'Sending');
                        } else {
                            return IsTpMatchCC(props?.sendingCC, newTP, 'Vendor', 'Sending');
                        }

                    }
                }
            } else {
                return true
            }
        }

        return IsTpMissing() && CheckTP_CC()

    }

    const onFinish = (tableItem) => {
        console.log("tableItem", tableItem);
        console.log("tableItem length", props.lineItems?.length + 1);

        if (validateTradingPartner()) {
            const itemNum = addPaddingToNum((props?.lineItems?.length + 1), 3)
            props.setlineItems(pre => [...pre, { ...tableItem, item: itemNum, id: itemNum }]);
            form.resetFields();
            props.close();
        }

    }

    const handleUpdate = () => {
        console.log("validation of modal", form.validateFields())
        // if(form.validateFields()){

        form.validateFields()
            .then(() => {
                if (validateTradingPartner()) {
                    const editedItem = form.getFieldsValue();
                    console.log("on click of upadte", editedItem);
                    props.setlineItems(pre => {
                        return pre.map(itm => {
                            if (props?.editItem?.item === itm.item) {
                                return { ...editedItem, item: props?.editItem?.item, id: props?.editItem?.item }
                            } else {
                                return itm
                            }
                        })
                    })
                    props.setEditItem(null);
                    form.resetFields();
                    props.close();
                }
            }).catch((errorInfo) => {
                console.log("errorInfo", errorInfo)
            })

        // }
    }

    const handlePostingKey = (value) => {
        setPostingKey(value);
        getAccountNumbers(value);
        form.setFieldValue('account', null)
    }

    const handleCostCenter = (value) => {
        const data = costCenterData?.filter(d => d.kostl === value);
        const taxCodeVal = form.getFieldValue('taxCode');
        console.log("data", data[0]?.prctr);
        form.setFieldValue('prctr', data[0]?.prctr?.trim());
        if (data[0]?.txjcd && data[0]?.txjcd !== ' ' && taxCodeVal?.trim()) {
            form.setFieldValue('taxJurisdiction', data[0]?.txjcd?.trim());
        }
    }

    const handleTaxCode = (value) => {
        if (!(value?.trim())) {
            form.setFieldValue('taxJurisdiction', null)
        }

        //checking and populating tax jurisdiction when tax code is changing
        const txjurVal = form.getFieldValue('taxJurisdiction');
        if (!(txjurVal?.trim()) && value?.trim()) {
            const cctrVal = form.getFieldValue('cctr');

            const data = costCenterData?.filter(d => d.kostl === cctrVal);
            if (data[0]?.txjcd && data[0]?.txjcd?.trim() && value?.trim()) {
                form.setFieldValue('taxJurisdiction', data[0]?.txjcd?.trim());
            }
        }

    }

    const handleAccount = (value) => {
        getTradingPartner(value);
    }

    const handleAdd = () => {
        modalSubmitBtnRef.current.click();
    }

    const handleCancel = () => {
        props.setEditItem(null);
        form.resetFields();
        props.close();
    }

    const getAccountNumbers = (pstngKey) => {

        setAccountOption(null); //if not done this it will append for every call of this func

        const filter = {
            bukrs: { eq: props.companyCode }
        }

        let queryType = '';
        let queryDataType = '';
        let acc = ''
        let desc = ''
        if (['40', '50'].includes(pstngKey)) {
            queryType = listCommXchargeDSkb1S;
            queryDataType = 'listCommXchargeDSkb1S';
            acc = 'saknr';
            desc = 'txt20';
        }
        if (props.currentUser && props.currentUser === 'ar') {
            if (['01', '11'].includes(pstngKey)) {
                queryType = listCommXchargeDKnb1S;
                queryDataType = 'listCommXchargeDKnb1S';
                acc = 'kunnr';
                desc = 'name1';
            }
        } else if (props.currentUser && props.currentUser === 'ap') {
            if (['21', '31'].includes(pstngKey)) {
                queryType = listCommXchargeDLFB1S;
                queryDataType = 'listCommXchargeDLFB1S';
                acc = 'lifnr';
                desc = 'name1';
            }
        }

        const getAccountNumbersLoop = (nxtTkn) => {
            let variablesParams = { filter: filter, limit: 10000 }; //100000
            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    filter: filter,
                    limit: 10000 //100000
                }
            }

            API
                .graphql({ query: queryType, variables: variablesParams })
                .then(response => {
                    console.log("account number response", response?.data[queryDataType]);
                    const token = response?.data[queryDataType]?.nextToken
                    const refinedOpt = response?.data[queryDataType]?.items?.map(d => {
                        if (d[desc]) {
                            return {
                                value: d[acc],
                                label: d[acc] + descSeparator + d[desc]
                            }
                        } else {
                            return {
                                value: d[acc],
                                label: d[acc]
                            }
                        }

                    })

                    // setAccountOption(refinedOpt);
                    setAccountOption(prev => {
                        const sorted = [...(prev ? prev : []), ...refinedOpt]
                        sorted.sort((a, b) => a.value?.localeCompare(b.value, undefined, {
                            numeric: true,
                            sensitivity: 'base'
                        }));
                        return sorted
                    });
                    // setAccountOption(prev => {
                    //     return [...(prev ? prev : []), ...refinedOpt]
                    // });

                    if (token) {
                        getAccountNumbersLoop(token);
                    }

                }).catch(err => {
                    console.log("err getting acc num", err);
                    messageApi.open({
                        type: 'error',
                        content: 'This is an error while loading account details.',
                        className: 'message-style',
                        duration: 10
                    });
                })

        }

        getAccountNumbersLoop('initial');


    }

    const getTradingPartner = (acc) => {

        let queryType = '';
        let queryDataType = '';

        if (props.currentUser && props.currentUser === 'ar') {
            queryType = getCommXchargeDKNA1;
            queryDataType = 'getCommXchargeDKNA1';
        } else if (props.currentUser && props.currentUser === 'ap') {
            queryType = getCommXchargeDLFA1;
            queryDataType = 'getCommXchargeDLFA1';
        }

        API
            .graphql({ query: queryType, variables: { id: acc } })
            .then(response => {
                console.log("trading partner response", response);
                const tp = response?.data[queryDataType]?.vbund

                form.setFieldValue('tradingPartner', tp);

            }).catch(err => {
                console.log("err getting trading partner", err)
            })
    }


    return (
        <div >
            {contextHolder}
            <Modal
                className='cc-table-info-modal'
                title={<Heading heading={`Create/Edit ${props?.currentUser?.toUpperCase()} Line Item`} />}
                centered
                open={props?.open}
                okText="Add Item"
                okType="danger"
                cancelButtonProps={{ "className": "ortho-btn" }}
                okButtonProps={{ "className": "ortho-btn" }}
                onCancel={handleCancel}
                width={1000}
                footer={[
                    <Button key="cancel" className='ortho-btn' onClick={handleCancel} >
                        Cancel
                    </Button>,
                    ...(
                        props.editItem !== null ?
                            [<Button key="update" className='ortho-btn' onClick={handleUpdate} >
                                Update
                            </Button>]
                            :
                            [
                                <Button key="add" className='ortho-btn' onClick={handleAdd} >
                                    Add
                                </Button>
                            ]
                    )

                ]}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                    size='small'
                // onFieldsChange = {(changedFields, allFields)=>{
                //     console.log("changedFields",changedFields);
                //     console.log("allFields",allFields);
                // }}
                // onValuesChange = {(changedValues, allValues)=>{
                //     console.log("changedValues",changedValues);
                //     console.log("allValues",allValues);
                // }}
                >

                    <div className='cc-modal-form-cnt' style={{ display: 'flex' }}>
                        <div className='cc-modal-form-child'>
                            <Form.Item
                                label="Posting Key"
                                name="postingKey"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select posting key',
                                    },
                                ]}
                            >
                                <Select
                                    className='cc-modal-input-field'
                                    placeholder="Select a posting key"
                                    options={postingKeyOptions}
                                    value={postingKey}
                                    optionLabelProp='label'
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={handlePostingKey}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Account"
                                name="account"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select account',
                                    },
                                ]}
                            >

                                <Select
                                    showSearch
                                    className='cc-modal-input-field'
                                    placeholder="Select account"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={handleAccount}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    optionLabelProp='label'
                                    options={accountOption}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Amount is mandatory',
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={1}
                                    type='number'
                                    style={{ width: "80%" }}
                                    placeholder='Enter amount (upto 2 decimal places)'
                                    ref={amountRef}
                                    value={amount}
                                    onChange={
                                        (value) => {
                                            // Creating a RegEx Pattern to check if amount have max 2 decimal places or not.
                                            const regExMatch = value?.toString().match(/^(\d*\.{0,1}\d{0,2}$)/);

                                            // The match() returns null if the 'string' does not match the RegEx
                                            const isValidAmt = (regExMatch === null) ? false : true;

                                            if (isValidAmt) {
                                                setAmount(value);
                                                form.setFieldValue('amount', value);
                                            }
                                            else {
                                                form.setFieldValue('amount', parseFloat(value).toFixed(2));

                                                // Blurring and Focusing for Synchronization
                                                amountRef.current.blur();
                                                amountRef.current.focus();
                                            }
                                        }
                                    }
                                    addonAfter={props?.currency}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={e => props.currencyDec?.toString() === '0' && ['.'].includes(e.key) && e.preventDefault()}
                                />
                                {console.log("UseState amount: ", amount)}
                                <Tooltip title={`Upto 2 decimal places allowed.`}>
                                    <InfoCircleOutlined
                                        className='cc-icons'
                                        style={{ marginLeft: "10px" }}
                                    />
                                </Tooltip>

                            </Form.Item>


                            <Form.Item
                                label="Tax Code"
                                name="taxCode"
                            >
                                <Select
                                    showSearch
                                    className='cc-modal-input-field'
                                    placeholder="Select tax code"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    getPopupContainer={trigger => trigger.parentNode}
                                    optionLabelProp='label'
                                    options={taxCodeOption && [emptyElement, ...taxCodeOption]}
                                    onChange={handleTaxCode}
                                />

                            </Form.Item>

                            <Form.Item
                                label="Tax Jurisdiction"
                                name="taxJurisdiction"
                                rules={[
                                    {
                                        validator: async (_, value) => {
                                            if (value && isNaN(value)) {
                                                throw new Error('Tax Jurisdiction must be a number')
                                            }
                                            if (value && value?.length !== 10) {
                                                throw new Error('Tax Jurisdiction should be 10 digits')
                                            }

                                        }
                                    }
                                ]}
                            >
                                <Input placeholder="Enter tax jurisdiction" className='cc-modal-input-field' maxLength={10} />
                            </Form.Item>

                            <Form.Item
                                label="Cost Center"
                                name="cctr"

                            >
                                <Select
                                    showSearch
                                    disabled={!['40', '50'].includes(postingKey)}
                                    className='cc-modal-input-field'
                                    placeholder="Select cost center"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={handleCostCenter}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    optionLabelProp='label'
                                    options={costCenterOption && [emptyElement, ...costCenterOption]}
                                />

                            </Form.Item>

                            <Form.Item
                                label="Profit Center"
                                name="prctr"
                                rules={[
                                    ...(
                                        ['40', '50'].includes(postingKey) ?
                                            [{
                                                required: true,
                                                message: 'Profit center is mandatory',
                                            }]
                                            :
                                            []
                                    )
                                ]}
                            >
                                <Select
                                    showSearch
                                    disabled={!['40', '50'].includes(postingKey)}
                                    className='cc-modal-input-field'
                                    placeholder="Select profit center"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    getPopupContainer={trigger => trigger.parentNode}
                                    optionLabelProp='label'
                                    options={profitCenterOption && [emptyElement, ...profitCenterOption]}
                                />

                            </Form.Item>

                            <Form.Item
                                label="Order Number"
                                name="orderNumber"

                            >
                                <Select
                                    showSearch
                                    className='cc-modal-input-field'
                                    placeholder="Select order number"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    dropdownStyle={{ height: "180px" }}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    optionLabelProp='label'
                                    options={orderNumOption && [emptyElement, ...orderNumOption]}
                                />

                            </Form.Item>

                        </div>

                        <div className='cc-modal-form-child'>

                            <Form.Item
                                label="Line Item Assignment"
                                name="lineItemAssignment"

                            >
                                <Input placeholder="Enter line item assignment" className='cc-modal-input-field  cc-padding-left' maxLength={18} showCount />
                            </Form.Item>

                            <Form.Item
                                label="Line Item Text"
                                name="lineItemText"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Line Item Text is mandatory',
                                    },
                                ]}
                            >
                                <Input placeholder="Enter line item text" className='cc-modal-input-field cc-padding-left' maxLength={50} showCount />

                            </Form.Item>

                            <Form.Item
                                label="External Material Group"
                                name="emg"

                            >
                                <Select
                                    showSearch
                                    className='cc-modal-input-field'
                                    placeholder="Select ext mat group"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    getPopupContainer={trigger => trigger.parentNode}
                                    optionLabelProp='label'
                                    options={extMatGroupOption && [emptyElement, ...extMatGroupOption]}
                                />

                            </Form.Item>

                            <Form.Item
                                label="Product"
                                name="product"

                            >
                                <Select
                                    showSearch
                                    className='cc-modal-input-field'
                                    placeholder="Select product material number"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    dropdownStyle={{ height: "180px" }}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    optionLabelProp='label'
                                    options={productOption && [emptyElement, ...productOption]}
                                />

                            </Form.Item>

                            <Form.Item
                                label="Quantity"
                                name="qty"
                                rules={[
                                    {
                                        validator: async (_, value) => {
                                            if (value && value.toString().length > 13) {
                                                throw new Error('must be a less than 13 character')
                                            }
                                        }
                                    }
                                ]}
                            >
                                <InputNumber
                                    type='number'
                                    className='cc-modal-input-field'
                                    placeholder='Enter quantity'
                                    maxLength={13}
                                    onWheel={(e) => e.target.blur()}
                                />

                            </Form.Item>

                            <Form.Item
                                label="Base Unit of Measure"
                                name="uom"

                            >
                                <Select
                                    showSearch
                                    className='cc-modal-input-field'
                                    placeholder="Select UOM"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    dropdownStyle={{ height: "180px" }}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    optionLabelProp='value'
                                    options={UOMOption && [emptyElement, ...UOMOption]}
                                />

                            </Form.Item>

                            <Form.Item
                                label="Trading Partner"
                                name="tradingPartner"
                            >
                                <Input disabled={true} className='cc-modal-input-field' />

                            </Form.Item>

                        </div>
                    </div>

                    <Button style={{ display: 'none' }} ref={modalSubmitBtnRef} htmlType="submit" >Add Item</Button>

                </Form>
            </Modal>
        </div>
    )
}

export default TableInfoModal