import React, { useRef,useLayoutEffect,useState} from "react";
//import "./styles.css";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";



function ReportsDashboard(props) {
    const chartRef = useRef(null);
    const  [data, setData] = useState(false);
  
    useLayoutEffect(() => {
      let root = am5.Root.new("chartdiv");
      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5percent.PieChart.new(root, {
        layout: root.verticalHorizontal
        }) 
      );

      let data = [
        {
          Company:"1001",
          sales: 114
  
        },
        {
          Company:"7515",
          sales: 13
        },
        {
          Company:"7225",
          sales: 3
        },
        {
          Company:"8245",
          sales: 1
        }
      
      ];

//       fetch('https://ocdx-dev.ent.us-east-1.aws.found.io/api/as/v1/engines/ic-cross-charge/search?query=Hat' , {
//         method: 'POST',
//         Authorization:`Bearer search-4d3fd6ei26kop6c3n72q6mv9`
//       })
//         .then((response) => response.json())
//         .then((result) => {
//           console.log('Success:', result);
//         })
//         .catch((error) => {
//           console.error('Error:', error);
//         });

// setData(true);
  
      
      // Create series
      var series = chart.series.push(
          am5percent.PieSeries.new(root, {
            name: "Sending Company",
            valueField: "sales",
            categoryField: "Company"
          })
        );
        series.data.setAll(data);
  
      // Add legend
      var legend = chart.children.push(am5.Legend.new(root, {
          centerX: am5.percent(50),
          x: am5.percent(50),
          layout: root.horizontalLayout
        }));
        
        legend.data.setAll(series.dataItems);
  
      // Add cursor
      chart.set("cursor", am5xy.XYCursor.new(root, {}));
    

      chartRef.current = chart;
  
      return () => {
        root.dispose();
      };
    }, []);
  
    // When the paddingRight prop changes it will update the chart
    useLayoutEffect(() => {
        chartRef.current.set("paddingRight", props.paddingRight);
    }, [props.paddingRight]);
  
    return (
      <div id="chartdiv" style={{ width: "100%", height: "500px", marginTop: "50px" }}></div>
    );
  }

export default ReportsDashboard;
