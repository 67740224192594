import React from 'react'

const CardTemplate = (props) => {
    const {children, heading, color, className} = props;
    return (
        <div className="col-lg-6 col-md-6 col-sm-12 col-xl-4">
            <div className={`cc-card ${className || ''}`}>
                <div className="cc-card-heading">
                    {heading}
                </div>
                {children}
            </div>
            <div className="cc-border" style={{backgroundColor:color}}></div>
        </div>
    )
}

export default CardTemplate