const awsmobile = {
  "aws_project_region": process.env.REACT_APP_Graphql_region,
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_CrossChargeGraphql_Endpoint,
  "aws_appsync_region": process.env.REACT_APP_Graphql_region,
  "aws_appsync_authenticationType": process.env.REACT_APP_Graphql_AuthenticationType,
  "userPoolId": process.env.REACT_APP_Auth_userPoolId,
  API: {
    endpoints: [
      {
        name: "CrossChargePresignedURL",
        endpoint: process.env.REACT_APP_CrossChargePresigned_Endpoint
      },
      {
        name: "CrossChargeElasticURL",
        endpoint: process.env.REACT_APP_CrossChargeElastic_Endpoint
      },
      {
        name: "CrossChargePresignedDownloadURL",
        endpoint: process.env.REACT_APP_CrossChargePresigned_Download_Endpoint
      }
    ]
  },
  Auth: {
    region: process.env.REACT_APP_Auth_region,
    userPoolWebClientId: process.env.REACT_APP_Auth_userPoolWebClientId,
    userPoolId: process.env.REACT_APP_Auth_userPoolId,
    mandatorySignIn: true,
    "oauth": {
      "domain": process.env.REACT_APP_Auth_oauth_domain,
      "scope": [
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": process.env.REACT_APP_Auth_redirectSignIn,
      "redirectSignOut": process.env.REACT_APP_Auth_redirectSignOut,
      "responseType": process.env.REACT_APP_Auth_responseType,
      "identity_id": process.env.REACT_APP_Auth_oauth_identity_id
    }
  }
};


export default awsmobile;