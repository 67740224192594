import { Table } from 'antd';
import React from 'react';
import { getArApColumns } from '../columns';
import Heading from '../HOC/Heading';
import HorizontalLine from '../HOC/HorizontalLine';
import { getPaginationOptions } from '../helper';

const ARInfo = (props) => {

    const { reviewData } = props;

    const arColumns = getArApColumns(reviewData?.currency, props?.currencyFormat);
    return (
        <div style={{marginTop:"30px"}}>
            <Heading
                heading="AR Information"
            />

            <HorizontalLine />

            <Table
                id="ar-table"
                size="small"
                className='arTable'
                style={{ width: "100%" }}
                // columns={screenSize > 767 ? orderItemsColumns : orderMobileItemsColumns}
                columns={arColumns}
                dataSource={reviewData?.arLineItems}
                rowKey='item'
                bordered
                pagination={getPaginationOptions(reviewData?.arLineItems?.length, 10)}
                scroll={{ x: 1200 }}
            />
        </div>
    )
}

export default ARInfo