import { Link } from "react-router-dom";
import { allowAR, AWAITING_FOR_AP_DOC_FAILED, AWAITING_FOR_AR_DOC_FAILED, AWAITING_FOR_ATTACHMENT_FAILED, capitalize, enableEditForAp, FAILED, formatNum } from "./helper"
import ActionDropdown from "./dashboardComponents/ActionDropdown"

export const getArApColumns = (currency, currencyFormat) => {
    return [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            // width: '5%',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Pstng Key',
            dataIndex: 'postingKey',
            key: 'postingKey',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Account',
            dataIndex: 'account',
            key: 'account',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => {
                if (currency) {
                    return (parseFloat(text).toLocaleString(currencyFormat) + ' ' + currency);
                } else {
                    return text;
                }

            },
            width: "8%"
        },
        {
            title: 'Tax Code',
            dataIndex: 'taxCode',
            key: 'taxCode',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Tax Jurisdiction',
            dataIndex: 'taxJurisdiction',
            key: 'taxJurisdiction',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'CCTR',
            dataIndex: 'cctr',
            key: 'cctr',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'PRCTR',
            dataIndex: 'prctr',
            key: 'prctr',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Order Number',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Line Item Assg.',
            dataIndex: 'lineItemAssignment',
            key: 'lineItemAssignment',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Line Item Text',
            dataIndex: 'lineItemText',
            key: 'lineItemText',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Ext. Mat. Grp.',
            dataIndex: 'emg',
            key: 'emg',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'UoM',
            dataIndex: 'uom',
            key: 'uom',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Trading Partner',
            dataIndex: 'tradingPartner',
            key: 'tradingPartner',
            render: (text, record) => {
                return text
            }
        }
    ]
}

export const getSupportingDocColumns = () => {
    return [

        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
            width: '20%',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '30%',
            render: (text, record) => {
                return text
            }
        }
    ]
}


export const getDashboardColumns = (currentUser, copyRequest, showErrorMsg, currencyFormat) => {
    return [
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return <ActionDropdown
                    copyRequest={copyRequest}
                    record={record}
                />
            },
            width: "15px"
        },
        {
            title: 'Request',
            dataIndex: 'requestId',
            key: 'requestId',
            defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.requestId - b.requestId,
            render: (text, record) => {
                if (allowAR(record?.status, record?.arRequestorId, currentUser) || enableEditForAp(record?.status, record?.apRequestorId, currentUser)) {
                    return <Link className='cc-link' to={`/request/${record.id}`} >{text}</Link>
                } else {
                    return <Link className='cc-link' to={`/review/${record.id}`} >{text}</Link>
                }

            }
        },
        {
            title: 'Request Date',
            dataIndex: 'requestDate',
            key: 'requestDate',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Initiator',
            dataIndex: 'arRequestorId',
            key: 'arRequestorId',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Request Description',
            dataIndex: 'requestDescription',
            key: 'requestDescription',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmt',
            key: 'totalAmt',
            className: 'amt-col',
            render: (text, record) => {
                if (record.currencyType) {
                    // console.log("string in",text && (`${formatNum(text)}    ${record.currencyType}`))
                    return <div style={{ marginRight: "20px" }}>
                        <span>{parseFloat(formatNum(text)).toLocaleString(currencyFormat)}</span> &nbsp; &nbsp;
                        <span>{record.currencyType}</span>
                    </div>
                } else {
                    return <div>{text}</div>
                }

            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if ([AWAITING_FOR_AR_DOC_FAILED, AWAITING_FOR_AP_DOC_FAILED, AWAITING_FOR_ATTACHMENT_FAILED, FAILED].includes(record?.status)) {
                    return <div className="cc-link" onClick={() => showErrorMsg(record?.sAPFailureMessage)}>{capitalize(record?.status)}</div>
                } else {
                    return <div>{capitalize(record?.status)}</div>
                }
            }
        },
        // {
        //     title: 'State Name',
        //     dataIndex: 'stateName',
        //     key: 'stateName',
        //     render: (text, record) => {
        //         return text
        //     }
        // },
        {
            title: 'Action Awaiting',
            dataIndex: 'actionAwaiting',
            key: 'actionAwaiting',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'AR Doc No.',
            dataIndex: 'arSAPDocNo',
            key: 'arSAPDocNo',
            render: (text, record) => {
                return text

            }
        },
        {
            title: 'AP Doc No.',
            dataIndex: 'apSAPDocNo',
            key: 'apSAPDocNo',
            render: (text, record) => {
                return text
            }
        },
    ]
}

export const getDashboardMobileColumns = (currentUser, copyRequest, showErrorMsg, currencyFormat) => {
    return [
        {
            title: "",
            key: 'mobileDashboard',
            defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.requestId - b.requestId,
            render: (record) => (
                <div className='mobileDashboardTable'>
                    <tr>
                        <td align="left" className='mobileHeading'>Request</td>
                        <td align="left">
                            {
                                (allowAR(record?.status, record?.arRequestorId, currentUser) || enableEditForAp(record?.status, record?.apRequestorId, currentUser)) ?
                                    <Link className='cc-link' to={`/request/${record.id}`} >{record.requestId}</Link>
                                    :
                                    <Link className='cc-link' to={`/review/${record.id}`} >{record.requestId}</Link>


                            }
                        </td>
                    </tr>

                    <tr>
                        <td align="left" className='mobileHeading'>Request Date</td>
                        <td align="left">{record.requestDate}</td>
                    </tr>
                    <tr>
                        <td align="left" className='mobileHeading'>Initiator</td>
                        <td align="left">{record.arRequestorId}</td>
                    </tr>

                    <tr>
                        <td align="left" className='mobileHeading'>Request Description</td>
                        <td align="left">{record?.requestDescription}</td>
                    </tr>

                    <tr>
                        <td align="left" className='mobileHeading'>Amount</td>
                        <td align="left">{(record.totalAmt && record.currencyType) ? (parseFloat(formatNum(record.totalAmt)).toLocaleString(currencyFormat) + ' ' + record.currencyType) : formatNum(record.totalAmt)}</td>
                    </tr>

                    <tr>
                        <td align="left" className='mobileHeading'>Status</td>
                        <td align="left">{
                            ([AWAITING_FOR_AR_DOC_FAILED, AWAITING_FOR_AP_DOC_FAILED, AWAITING_FOR_ATTACHMENT_FAILED, FAILED].includes(record?.status)) ?
                                <div className="cc-link" onClick={() => showErrorMsg(record?.sAPFailureMessage)}>{capitalize(record?.status)}</div>
                                :
                                <div>{capitalize(record?.status)}</div>
                        }</td>

                    </tr>

                    <tr>
                        <td align="left" className='mobileHeading'>Action Awaiting</td>
                        <td align="left">{record.actionAwaiting}</td>
                    </tr>


                    <tr>
                        <td align="left" className='mobileHeading'>AR Doc No.</td>
                        <td align="left">{record.arSAPDocNo}</td>
                    </tr>

                    <tr>
                        <td align="left" className='mobileHeading'>AP Doc No.</td>
                        <td align="left">{record.apSAPDocNo}</td>
                    </tr>

                    <tr>
                        <div className='optionBtn-mobile'>
                            <ActionDropdown
                                copyRequest={copyRequest}
                                record={record}
                            />
                        </div>
                    </tr>

                </div>
            )
        }
    ]
}


export const getArApproverDashboardColumns = () => {
    return [
        {
            title: 'Request',
            dataIndex: 'requestId',
            key: 'requestId',
            defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.requestId?.localeCompare(b.requestId, undefined, {
                numeric: true,
                sensitivity: 'base'
            }),
            render: (text, record) => {

                return <Link className='cc-link' to={`/review/${record.id}`} >{text}</Link>

            }
        },
        {
            title: 'Request Date',
            dataIndex: 'requestDate',
            key: 'requestDate',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Initiator',
            dataIndex: 'arRequestor',
            key: 'arRequestor',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Status',
            dataIndex: 'formStatus',
            key: 'formStatus',
            render: (text, record) => {
                return capitalize(text)
            }
        }
    ]
}

export const getArApproverDashboardMobileColumns = () => {
    return [
        {
            title: "",
            key: 'mobileDashboard',
            defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.requestId?.localeCompare(b.requestId, undefined, {
                numeric: true,
                sensitivity: 'base'
            }),
            render: (record) => (
                <div className='mobileDashboardTable'>
                    <tr>
                        <td align="left" className='mobileHeading'>Request</td>
                        <td align="left">

                            <Link className='cc-link' to={`/review/${record.id}`} >{record.requestId}</Link>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" className='mobileHeading'>Request Date</td>
                        <td align="left">{record.requestDate}</td>
                    </tr>
                    <tr>
                        <td align="left" className='mobileHeading'>Initiator</td>
                        <td align="left">{record.arRequestor}</td>
                    </tr>

                    <tr>
                        <td align="left" className='mobileHeading'>Status</td>
                        <td align="left">{capitalize(record.formStatus)}</td>
                    </tr>

                </div>
            )
        }
    ]
}

export const getExcelProcessColumns = () => {
    return [

        {
            title: 'Excel Req No.',
            dataIndex: 'excelReqNo',
            key: 'excelReqNo',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Request ID',
            dataIndex: 'requestId',
            key: 'requestId',
            render: (text, record) => {
                return <Link className='cc-link' to={`/request/${record.id}`} target="_blank" rel="noopener noreferrer" >{text}</Link>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            render: (text, record) => {
                return text
            }
        },
    ]
}