import React from 'react'

const LabelInfoPair = (props) => {
    const { label, information, highlight, note, style } = props

    return (
        <div className="d-flex" style={style ?? {}}>
            <label className="cc-card-text">{label}:&nbsp;</label>
            <p title={information} className={`cc-card-text cc-card-info ${highlight === true ? 'highlight' : (note === true ? 'cc-note' : '')}`}>{information}</p>
        </div>
    )

}

export default LabelInfoPair