
import React from 'react'
import { Input } from 'antd'

import Heading from '../HOC/Heading'
import HorizontalLine from '../HOC/HorizontalLine'

const { TextArea } = Input;

const CommentHistory = (props) => {
    const { reviewData } = props;
    return (
        <>
            {
                reviewData?.commentHistoryItems?.length > 0 &&
                <div className='cmt-history-cnt'>
                    <Heading
                        heading='Comments History'
                    />
                    <HorizontalLine />

                    <TextArea

                        autoSize={{ minRows: 4, maxRows: 10 }}
                        readOnly
                        value={reviewData?.commentHistoryItems?.map(item => `${item.mailId} \n ${item.time} \n ${item.comment}`).join('\n\n')}
                    />

                </div>
            }

        </>
    )
}

export default CommentHistory