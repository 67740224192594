import React, { useState } from 'react'
import { Spin } from 'antd';
import DashboardTable from '../dashboardComponents/DashboardTable';

import '../../css/reviewform.css'
import '../../css/dashboard.css'

const Dashboard = () => {

  const [isLoading, setIsLoading] = useState(false);


  return (
    <Spin spinning={isLoading}>
      {/* <Header /> */}

      <div className='dashboard-cnt'>

        <DashboardTable 
          setIsLoading={setIsLoading}
        />
      </div>

    </Spin>
  )
}

export default Dashboard