import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import CommentModal from './CommentModal'
import ContextData from '../context/ContextData';
import { AdminReject, AdminRejectEnd, ADMIN_REJECTED, ADMIN_REQUESTED_AP_RESUBMISSION, ADMIN_REQUESTED_AR_RESUBMISSION, AP_DRAFT, AWAITING_AP_APPROVAL, AWAITING_AP_REQUESTOR_SUBMISSION, AWAITING_AP_RESUBMISSION, AWAITING_AR_APPROVAL, AWAITING_FOR_AP_DOC_FAILED, AWAITING_FOR_AR_DOC_FAILED, AWAITING_FOR_ATTACHMENT_FAILED, formatTimezone, Resend } from '../helper';
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { adminactionperformerCrossChargeForm, apactionperformerCrossChargeForm, aractionperformerCrossChargeForm } from '../../graphql/mutations';

const ApprovalBtns = (props) => {

  const [openComment, setOpenComment] = useState(false);
  const [actionType, setActionType] = useState('');
  const [showBtn, setShowBtn] = useState(false);

  const contextValue = useContext(ContextData);
  const { currentUser, isAdmin } = contextValue;

  const navigate = useNavigate();

  
  useEffect(() => {
    if (props?.reviewData?.formStatus === AWAITING_AR_APPROVAL) {
      handleShowOrHideBtn(props?.reviewData?.arApprovers, props?.reviewData?.currARApproverLVL, "arapprover", AWAITING_AR_APPROVAL)
    } else if (props?.reviewData?.formStatus === AWAITING_AP_APPROVAL) {
      handleShowOrHideBtn(props?.reviewData?.apApprovers, props?.reviewData?.currAPApproverLVL, "apapprover", AWAITING_AP_APPROVAL)
    }

  }, [currentUser,props?.reviewData])


  const handleAction = (type) => {
    setActionType(type);
    setOpenComment(true);
  }

  const handleSubmit = (cmnts, actnType) => {
 
    props.setIsLoading(true);

    const currARApproverLVL = props?.reviewData?.currARApproverLVL
    const currAPApproverLVL = props?.reviewData?.currAPApproverLVL
    const totalAPApproverLVL = props?.reviewData?.totalAPApproverLVL
    const curFormStatus = props?.reviewData?.formStatus

    if([ AdminRejectEnd ].includes(actnType)){

      handleAdminReject(cmnts, totalAPApproverLVL);

    }else if([ Resend, AdminReject ].includes(actnType)){

      callAdminAction(cmnts, actnType, curFormStatus, currARApproverLVL, currAPApproverLVL);

    }else{

      let approverType = ''
      let approverMetaType = ''
      let currentLvl = ''
      let currentApproverLvlType = ''
      let userType = ''
      let queryType = ''

      if(curFormStatus === AWAITING_AR_APPROVAL){

        approverType = "arapprover";
        approverMetaType = "arApprovers";
        currentLvl = currARApproverLVL;
        currentApproverLvlType = 'currARApproverLVL';
        userType = 'AR';
        queryType = aractionperformerCrossChargeForm;

      }else if(curFormStatus === AWAITING_AP_APPROVAL){

        approverType = "apapprover";
        approverMetaType = "apApprovers";
        currentLvl = currAPApproverLVL;
        currentApproverLvlType = 'currAPApproverLVL';
        userType = 'AP';
        queryType = apactionperformerCrossChargeForm;

      }


      

      const approverStatus = approverType + currentLvl + "_status"
      const doa = approverType + currentLvl + "_dateOfApproval"
      const comment = approverType + currentLvl + "_comments"

      let status = ''
      let successMsg = ''

      if (actnType === 'Approve') {
        status = userType+"_APPROVED"
        successMsg = "approved"
      } else if (actnType === 'Reject') {
        status = userType+"_REJECTED"
        successMsg = "rejected"
      } else if (actnType === 'Additional Infomation') {
        status = userType+"_REQUESTED_INFORMATION"
        successMsg = "sent for additional information"
      }

      const payload = {
        ...props?.reviewData,
        "actionType": status,
        "isadminAction": "N",
        ...(cmnts && {
          "commentHistoryItems": [
            {
              "mailId": currentUser?.userEmail,
              "time": formatTimezone(),
              "comment": cmnts
            },
            ...(props?.reviewData?.commentHistoryItems ? props?.reviewData?.commentHistoryItems : [])
            
          ]
        })
        ,
        ...(actnType === "Approve" && { [currentApproverLvlType]: currentLvl + 1 }), //increasing current lvl by 1 only for approve
        [approverMetaType]: { ...(props?.reviewData[approverMetaType] && props?.reviewData[approverMetaType]), [approverStatus]: status, [doa]: formatTimezone(), [comment]: cmnts }, // update curent approver details
      }

      console.log("payload in approval", payload)

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

      API
        .graphql({ query: queryType, variables: { input: payload } })
        .then((response) => {
          props.setIsLoading(false);
          console.log("APprove res", response)
          Modal.success({
            content: `Request has been ${successMsg}`,
            onOk: () => {
              navigate('/')
            },
            okButtonProps: { "className": "ortho-btn" }
          });
        }).catch(err => {
          console.log("error approving", err)
          props.setIsLoading(false);
          props.messageApi.open({
            type: 'error',
            content: 'There is an error while approving/rejecting/sending for more info.',
            className: 'message-style',
            duration: 5
          });
        })
    }

  }

  const handleShowOrHideBtn = (approversData, currentLvl, approverType, waitingStatus) => {
    const currentApprover = approverType + currentLvl + "_id"
    const currentLevelStatus = approverType + currentLvl + "_status"
    
    // if (approversData && ("APALIMAR" === approversData[currentApprover]?.toUpperCase() && approversData[currentLevelStatus]?.toUpperCase() === waitingStatus?.toUpperCase())) {
    if (approversData && (currentUser?.userID?.toUpperCase() === approversData[currentApprover]?.toUpperCase() && approversData[currentLevelStatus]?.toUpperCase() === waitingStatus?.toUpperCase())) {
      console.log("checking approve = true");
      setShowBtn(true);

    } else {
      console.log("checking approve = false")
      setShowBtn(false);
    }
  }

  const callAdminAction = (cmnts, actnType, curFormStatus, currARApproverLVL, currAPApproverLVL) => {
    console.log("admin action called")

    let status = ''
    let successMsg = ''
    let adminActionLvlType = ''
    let adminActionLvl = ''

    if( actnType === AdminReject){
      status = ADMIN_REJECTED;
      successMsg = "rejected";
      // if([AWAITING_AP_REQUESTOR_SUBMISSION, AP_DRAFT, AWAITING_AP_RESUBMISSION, ADMIN_REQUESTED_AP_RESUBMISSION].includes(curFormStatus)){
      //   adminActionLvlType = "adminActionLvl_AR";
      //   adminActionLvl = parseInt(currARApproverLVL) + 1;
      // }else 
      if([AWAITING_AR_APPROVAL, AWAITING_AP_REQUESTOR_SUBMISSION, AP_DRAFT, AWAITING_AP_RESUBMISSION, ADMIN_REQUESTED_AP_RESUBMISSION].includes(curFormStatus)){
        adminActionLvlType = "adminActionLvl_AR";
        adminActionLvl = currARApproverLVL;
      }else if(curFormStatus === AWAITING_AP_APPROVAL){
        adminActionLvlType = "adminActionLvl_AP";
        adminActionLvl = currAPApproverLVL;
      }

    }else if([AWAITING_AP_REQUESTOR_SUBMISSION, AP_DRAFT, AWAITING_AP_RESUBMISSION, ADMIN_REQUESTED_AP_RESUBMISSION].includes(curFormStatus)){
      status = ADMIN_REQUESTED_AR_RESUBMISSION;
      successMsg = "resent";
      adminActionLvlType = "adminActionLvl_AR";
      adminActionLvl = parseInt(currARApproverLVL) + 1;
    }else if(curFormStatus === AWAITING_AR_APPROVAL && actnType === Resend){
      status = ADMIN_REQUESTED_AR_RESUBMISSION;
      successMsg = "resent";
      adminActionLvlType = "adminActionLvl_AR";
      adminActionLvl = currARApproverLVL;
    }else if(curFormStatus === AWAITING_AP_APPROVAL && actnType === Resend){
      status = ADMIN_REQUESTED_AP_RESUBMISSION;
      successMsg = "resent";
      adminActionLvlType = "adminActionLvl_AP";
      adminActionLvl = currAPApproverLVL;
    }

    const payload = {
      ...props?.reviewData,
      actionType: status,
      formStatus: status,
      isadminAction: "Y",
      adminUserId: currentUser?.userID,
      adminEmailAddress: currentUser?.userEmail,
      adminComments: cmnts,
      adminsubmitedDate: formatTimezone(),
      [adminActionLvlType]: adminActionLvl,
      ...(cmnts && {
        "commentHistoryItems": [
          {
            "mailId": currentUser?.userEmail,
            "time": formatTimezone(),
            "comment": cmnts
          },
          ...(props?.reviewData?.commentHistoryItems ? props?.reviewData?.commentHistoryItems : [])
          
        ]
      })
    }

    
    console.log("payload in admin", payload)

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    API
      .graphql({ query: adminactionperformerCrossChargeForm, variables: { input: payload } })
      .then((response) => {
        props.setIsLoading(false);
        console.log("admin res", response)
        Modal.success({
          content: `Request has been ${successMsg}`,
          onOk: () => {
            navigate('/')
          },
          okButtonProps: { "className": "ortho-btn" }
        });
      }).catch(err => {
        console.log("error admin actn", err)
        props.setIsLoading(false);
        props.messageApi.open({
          type: 'error',
          content: 'There is an error while rejecting/resending.',
          className: 'message-style',
          duration: 5
        });
      })

  }

  const handleAdminReject = (cmnts, totalAPApproverLVL)=>{
    const payload = {
      ...props?.reviewData,
      actionType: ADMIN_REJECTED,
      formStatus: ADMIN_REJECTED,
      isadminAction: "Y",
      adminUserId: currentUser?.userID,
      adminEmailAddress: currentUser?.userEmail,
      adminComments: cmnts,
      adminsubmitedDate: formatTimezone(),
      adminActionLvl_AP: totalAPApproverLVL + 1,
      ...(cmnts && {
        "commentHistoryItems": [
          {
            "mailId": currentUser?.userEmail,
            "time": formatTimezone(),
            "comment": cmnts
          },
          ...(props?.reviewData?.commentHistoryItems ? props?.reviewData?.commentHistoryItems : [])
          
        ]
      })
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    API
      .graphql({ query: adminactionperformerCrossChargeForm, variables: { input: payload } })
      .then((response) => {
        props.setIsLoading(false);
        console.log("admin res", response)
        Modal.success({
          content: `Request has been rejected`,
          onOk: () => {
            navigate('/')
          },
          okButtonProps: { "className": "ortho-btn" }
        });
      }).catch(err => {
        console.log("error admin actn", err)
        props.setIsLoading(false);
        props.messageApi.open({
          type: 'error',
          content: 'There is an error while rejecting.',
          className: 'message-style',
          duration: 5
        });
      })

      
  }

  return (
    <>
      {
        openComment &&

        <CommentModal
          actionType={actionType}
          openComment={openComment}
          closeComment={() => setOpenComment(false)}
          onSubmit={handleSubmit}
        />
      }
      {
        showBtn ?
          <div className='cc-approval-btns-cnt'>
            <Button className='ortho-btn' onClick={() => handleAction('Approve')}>Approve</Button>
            <Button className='ortho-btn' onClick={() => handleAction('Reject')}>Reject</Button>
            <Button className='ortho-btn' onClick={() => handleAction('Additional Infomation')}>Request Additional Information</Button>
          </div>
        :
        ( isAdmin && [AWAITING_AR_APPROVAL, AWAITING_AP_APPROVAL, AWAITING_AP_REQUESTOR_SUBMISSION, AP_DRAFT, AWAITING_AP_RESUBMISSION, ADMIN_REQUESTED_AP_RESUBMISSION].includes(props?.reviewData?.formStatus) ) ?
          <div className='cc-admin-btns-cnt'>
            <Button className='ortho-btn' onClick={() => handleAction(Resend)}>{Resend}</Button>
            <Button className='ortho-btn' onClick={() => handleAction(AdminReject)}>Reject</Button>
          </div>
        :
        (  isAdmin && [AWAITING_FOR_AR_DOC_FAILED, AWAITING_FOR_AP_DOC_FAILED, AWAITING_FOR_ATTACHMENT_FAILED].includes(props?.reviewData?.formStatus) ) ?
        <div className='cc-admin-btns-cnt'>
            <Button className='ortho-btn' onClick={() => handleAction(AdminRejectEnd)}>Reject</Button>
        </div>
        :
        <div></div>
      }
    </>

  )
}

export default ApprovalBtns