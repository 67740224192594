import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { Dropdown, message } from 'antd';
import ContextData from '../context/ContextData';
import { AWAITING_FOR_AP_DOC_FAILED, AWAITING_FOR_AR_DOC_FAILED, AWAITING_FOR_ATTACHMENT_FAILED } from '../helper';
import { getCrossChargeForm } from '../../graphql/queries';
import { arretryCrossChargeForm } from '../../graphql/mutations';
import { API } from 'aws-amplify';

const ActionDropdown = (props) => {

    const {copyRequest, record} = props;

    const contextValue = useContext(ContextData);
    const { currentUser } = contextValue;

    const [messageApi, contextHolder] = message.useMessage();

    const key = "retry";

    const retryRequest = (formID) => {
        messageApi.open({
            key,
            type: 'loading',
            content: 'Retrying to post the request...',
        });

        API
            .graphql({ query: getCrossChargeForm, variables: { id: formID } })
            .then((response) => {
                const formData = response?.data?.getCrossChargeForm;
                callRetryRequest(formData);

            }).catch((err) => {
                console.log("get form err", err);
                
            })
    }

    const callRetryRequest = (formData) =>{
        API
            .graphql({ query: arretryCrossChargeForm, variables: { input: formData } })
            .then((response) => {
                console.log("retry res", response)
                messageApi.open({
                    key,
                    type: 'success',
                    content: 'Retry was successfull',
                    duration: 2,
                })

            }).catch((err) => {
                console.log("retry req err", err);
                
            })
    }

    const items = [
        {
            key: 'copy request',
            label: (
                <div className='new-req-option' onClick={()=>copyRequest(record?.id)}>
                    Copy request {record?.requestId}
                </div>
            ),
            disabled: (currentUser?.userID?.toUpperCase() !== record?.arRequestorId?.toUpperCase())
        },

        ...(
            ( [AWAITING_FOR_AR_DOC_FAILED, AWAITING_FOR_AP_DOC_FAILED, AWAITING_FOR_ATTACHMENT_FAILED].includes(record.status)
                && currentUser?.userID?.toUpperCase() === record?.arRequestorId?.toUpperCase() 
            ) ?
                [{
                    key: 'retry request',
                    label: (
                        <div className='new-req-option' onClick={()=>{retryRequest(record?.id)}}>
                            Retry request {record?.requestId}
                        </div>
                    ),
                    disabled: (currentUser?.userID?.toUpperCase() !== record?.arRequestorId?.toUpperCase())
                }]
                :
                []
        )
        
    ]
    return (
        <>
            {contextHolder}
            <Dropdown
                menu={{
                    items,
                }}
                placement="bottomRight"
                className='cc-new-request-dropdown'
                trigger={['click']}
            >
                <FontAwesomeIcon icon={faEllipsisVertical}
                    className='cc-icons'
                />
            </Dropdown>
        </>
    )
}

export default ActionDropdown