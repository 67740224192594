import React, {useState, useEffect} from 'react';
import { Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

import Heading from '../HOC/Heading'
import HorizontalLine from '../HOC/HorizontalLine'
import TableInfoModal from './TableInfoModal'
import { allowAR, DeleteItems, enableEditForAp, formDisable, getPaginationOptions } from '../helper';
import { getArApColumns } from '../columns';

const APTable = (props) => {

    const {formData} = props;

    const [APItems, setAPItems] = useState([]);
    const [editItem, setEditItem] = useState(null);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [open, setOpen] = useState(false);

    // const formDisableCondition = formDisable(props?.formStatus)
    const formDisableCondition = !( allowAR(props?.formStatus, formData?.arRequestor, props?.currentUser) )

    useEffect(()=>{
        props.getAPItems(APItems)
    },[APItems])

    useEffect(()=>{
        if(props.APItemsUpdate){
            setAPItems(props.APItemsUpdate);
        }       
    },[props.APItemsUpdate])

    const apColumns = [
        ...getArApColumns(props?.currency, props?.currencyFormat),
        {
            title: 'Action',
            
            render: (text, record) => {
                return (
                    <div className = 'action-column'>
                    {
                        (!formDisableCondition || enableEditForAp(props?.formStatus, props?.apRequestor, props?.currentUser) ) &&
                            <FontAwesomeIcon icon={faPenToSquare}
                                title = 'Edit item'
                                className='cc-icons'
                                onClick={() => {
                                    setEditItem(record);
                                    setOpen(true);
                                }}
                            />
                    }
                    </div>

                )

            }
        }
    ]


    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        // getCheckboxProps: (record) => {
        //     return{
        //         disabled:true
        //     }
        // }
    };

  return (
    <div style={{marginTop:"30px"}}>
            <Heading
                heading="AP Information"
            >
                {
                    (!formDisableCondition || enableEditForAp(props?.formStatus, props?.apRequestor, props?.currentUser) ) &&
                        <div className='cc-item-add-dlt-cnt'>
                            <FontAwesomeIcon icon={faPlus} 
                                title = 'Add item'
                                className='cc-icons cc-add-icon'
                                onClick={() => setOpen(true)}
                            />

                            <FontAwesomeIcon icon={faMinus} 
                                title = 'Delete selected items'
                                className='cc-icons cc-minus-icon'
                                onClick={()=> DeleteItems(APItems,selectedRowKeys, setAPItems)}
                            />
                        </div>
                }
            </Heading>
            <HorizontalLine />

            


            <Table
                id="ap-table"
                size="small"
                className='apTable'
                style={{ width: "100%" }}
                // columns={screenSize > 767 ? orderItemsColumns : orderMobileItemsColumns}
                columns={apColumns}
                dataSource={APItems}
                rowKey='item'
                bordered
                rowSelection={rowSelection}
                pagination={getPaginationOptions(APItems?.length, 10)}
                scroll={{ x: 1200 }}
            />

            {
                open &&
                <TableInfoModal
                    requestType={props?.requestType}
                    currency={props?.currency}
                    currencyDec={props?.currencyDec}
                    companyCode = {props?.companyCode}
                    fieldOptions = {props?.fieldOptions}
                    sendingCC = {props?.sendingCC}
                    open={open}
                    close={() => setOpen(false)}
                    lineItems={APItems}
                    setlineItems={setAPItems}
                    editItem={editItem}
                    setEditItem={setEditItem}
                    currentUser = 'ap'

                />
            }

        </div>
  )
}

export default APTable