import React, { useContext, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import '../../css/reviewform.css'
import CardContainer from '../reviewFormComponents/CardContainer'
import { getCrossChargeForm } from '../../graphql/queries'
import { useParams } from 'react-router-dom'
import { message, Spin } from 'antd'
import ARInfo from '../dashboardComponents/ARInfo'
import APInfo from '../dashboardComponents/APInfo'
import ApprovalBtns from '../reviewFormComponents/ApprovalBtns'
import SupportingDocsList from '../reviewFormComponents/SupportingDocsList'
import CommentHistory from '../reviewFormComponents/CommentHistory'
import Stages from '../reviewFormComponents/Stages'
import ContextData from '../context/ContextData'
import { cancelTimerInterval } from '../helper'

const ReviewForm = () => {

  let { formID } = useParams();

  const [reviewData, setReviewData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const contextValue = useContext(ContextData);
  const { timerCtxt, currencyFormat } = contextValue;

  const [messageApi, contextHolder] = message.useMessage();

  // to clear all the leftout timeouts from a component
  useEffect(()=>{

    const timerCancel = setInterval(()=>{
      const { timerCtxt } = contextValue;
      for(let j=0; j<timerCtxt.length; j++){
        clearTimeout(timerCtxt[j])
      }
    },cancelTimerInterval)
    
    return () => clearInterval(timerCancel)
    
  },[timerCtxt])

  const limit = 3
  useEffect(() => {
    getFormDetails(limit);
  }, [formID])

  

  const getFormDetails = (limitLeft) => {
    if(limitLeft <= 0){
      setIsLoading(false);
      messageApi.open({
        type: 'error',
        content: 'There is an error while loading the request. Please reload the page after sometime',
        className: 'message-style',
        duration: 5
      });
    }else{
      setIsLoading(true);
      API
        .graphql({ query: getCrossChargeForm, variables: { id: formID } })
        .then((response) => {
          setReviewData(response?.data?.getCrossChargeForm);
          setIsLoading(false);
        }).catch(err => {
          console.log("error in review form data", err)
          limitLeft--
          getFormDetails(limitLeft);
          // setIsLoading(false);
          // messageApi.open({
          //   type: 'error',
          //   content: 'There is an error while loading data.',
          //   className: 'message-style',
          //   duration: 5
          // });
        })
    }
    
  }

  console.log("reviewData in review", reviewData)

  return (
    <Spin spinning={isLoading} size='large'>
    {contextHolder}
      {/* <Header /> */}
      <Stages 
        reviewData={reviewData}
      />

      <div className='cc-reviewform-cnt'>

        <CardContainer
          reviewData={reviewData}
        />

        <ARInfo          
          reviewData={reviewData} 
          currencyFormat = {currencyFormat}
        />

        <APInfo
          reviewData={reviewData} 
          currencyFormat = {currencyFormat}
        />

        <div className='doc-comment-cnt'>
          <SupportingDocsList 
            reviewData={reviewData}
          />

          <CommentHistory 
            reviewData={reviewData}
          />
        </div>
        
        {/* {
          ['ar_approval_waiting','ap_approval_waiting'].includes(reviewData?.formStatus?.toLowerCase()) &&  */}
          <ApprovalBtns 
            reviewData={reviewData}
            setIsLoading = {setIsLoading}
            messageApi = {messageApi}
          />
        {/* } */}
        
      </div>
    </Spin>
  )
}

export default ReviewForm