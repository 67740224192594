/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCommDXchargeTwew = /* GraphQL */ `
  query GetCommDXchargeTwew($id: String!) {
    getCommDXchargeTwew(id: $id) {
      dtype
      ewbez
      extwg
      id
      mandt
      
    }
  }
`;
export const getCommXchargeDAgrusers = /* GraphQL */ `
  query GetCommXchargeDAgrusers($id: String!) {
    getCommXchargeDAgrusers(id: $id) {
      cclist
      change_dat
      change_tim
      dtype
      from_dat
      id
      mandt
      to_dat
      uname
      
    }
  }
`;
export const getCommXchargeDArApprovers = /* GraphQL */ `
  query GetCommXchargeDArApprovers($id: String!) {
    getCommXchargeDArApprovers(id: $id) {
      actualARApprovers
      arRequestor
      arapprover1_comments
      arapprover1_dateOfApproval
      arapprover1_dateOfEntry
      arapprover1_emailAddr
      arapprover1_id
      arapprover1_isdelegator
      arapprover1_lvl
      arapprover1_mgremailaddress
      arapprover1_mgrid
      arapprover1_name
      arapprover1_status
      arapprover2_comments
      arapprover2_dateOfApproval
      arapprover2_dateOfEntry
      arapprover2_emailAddr
      arapprover2_id
      arapprover2_isdelegator
      arapprover2_lvl
      arapprover2_mgremailaddress
      arapprover2_mgrid
      arapprover2_name
      arapprover2_status
      arapprover3_comments
      arapprover3_dateOfApproval
      arapprover3_dateOfEntry
      arapprover3_emailAddr
      arapprover3_id
      arapprover3_isdelegator
      arapprover3_lvl
      arapprover3_mgremailaddress
      arapprover3_mgrid
      arapprover3_name
      arapprover3_status
      arapprover4_comments
      arapprover4_dateOfApproval
      arapprover4_dateOfEntry
      arapprover4_emailAddr
      arapprover4_id
      arapprover4_isdelegator
      arapprover4_lvl
      arapprover4_mgremailaddress
      arapprover4_mgrid
      arapprover4_name
      arapprover4_status
      arapprover5_comments
      arapprover5_dateOfApproval
      arapprover5_dateOfEntry
      arapprover5_emailAddr
      arapprover5_id
      arapprover5_isdelegator
      arapprover5_lvl
      arapprover5_mgremailaddress
      arapprover5_mgrid
      arapprover5_name
      arapprover5_status
      dType
      existARApprovers
      formStatus
      id
      requestDate
      requestId
      
    }
  }
`;
export const getCommXchargeDCepc = /* GraphQL */ `
  query GetCommXchargeDCepc($id: String!) {
    getCommXchargeDCepc(id: $id) {
      bukrs
      dataab
      databi
      dtype
      ersda
      id
      kokrs
      ltext
      mandt
      prctr
      
    }
  }
`;
export const getCommXchargeDCoas = /* GraphQL */ `
  query GetCommXchargeDCoas($id: String!) {
    getCommXchargeDCoas(id: $id) {
      aufnr
      bukrs
      dtype
      id
      ktext
      mandt
      
    }
  }
`;
export const getCommXchargeDCsks = /* GraphQL */ `
  query GetCommXchargeDCsks($id: String!) {
    getCommXchargeDCsks(id: $id) {
      bukrs
      dataab
      databi
      dtype
      ersda
      id
      kokrs
      kostl
      ltext
      mandt
      prctr
      txjcd
      
    }
  }
`;
export const getCommXchargeDHrusd2 = /* GraphQL */ `
  query GetCommXchargeDHrusd2($id: String!) {
    getCommXchargeDHrusd2(id: $id) {
      active
      begda
      dtype
      endda
      id
      mandt
      rep_name
      us_name
      
    }
  }
`;
export const getCommXchargeDJ1bbranch = /* GraphQL */ `
  query GetCommXchargeDJ1bbranch($id: String!) {
    getCommXchargeDJ1bbranch(id: $id) {
      branch
      bukrs
      cgc_branch
      dtype
      id
      mandt
      name
      
    }
  }
`;
export const getCommXchargeDKNA1 = /* GraphQL */ `
  query GetCommXchargeDKNA1($id: String!) {
    getCommXchargeDKNA1(id: $id) {
      dtype
      id
      kunnr
      mandt
      vbund
      
    }
  }
`;
export const getCommXchargeDKnb1 = /* GraphQL */ `
  query GetCommXchargeDKnb1($id: String!) {
    getCommXchargeDKnb1(id: $id) {
      bukrs
      dtype
      id
      kunnr
      mandt
      name1
      
    }
  }
`;
export const getCommXchargeDLFA1 = /* GraphQL */ `
  query GetCommXchargeDLFA1($id: String!) {
    getCommXchargeDLFA1(id: $id) {
      dtype
      erdat
      id
      lifnr
      loevm
      mandt
      vbund
      
    }
  }
`;
export const getCommXchargeDLFB1 = /* GraphQL */ `
  query GetCommXchargeDLFB1($id: String!) {
    getCommXchargeDLFB1(id: $id) {
      bukrs
      dtype
      id
      lifnr
      mandt
      name1
      
    }
  }
`;
export const getCommXchargeDMara = /* GraphQL */ `
  query GetCommXchargeDMara($id: String!) {
    getCommXchargeDMara(id: $id) {
      bismt
      dtype
      ersda
      extwg
      id
      laeda
      maktx
      mandt
      matkl
      matnr
      mtart
      
    }
  }
`;
export const getCommXchargeDReportdashboard = /* GraphQL */ `
  query GetCommXchargeDReportdashboard($id: String!) {
    getCommXchargeDReportdashboard(id: $id) {
      actionAwaiting
      actionAwaitingEmailAddr
      actionType
      apRequestSubmittedDate
      apRequestorEmailId
      apRequestorId
      apRequestor_dateOfEntry
      apRequestor_mgremailaddress
      apRequestor_mgrid
      apSAPDocNo
      apSAPErrorMessage
      ap_doc_created_at
      ap_doc_created_by
      ap_doc_created_on
      ap_pay_term
      ap_pay_term_desc
      ap_posting_period
      ap_posting_year
      apapprover1_comments
      apapprover1_dateOfApproval
      apapprover1_dateOfEntry
      apapprover1_emailAddr
      apapprover1_id
      apapprover1_isdelegator
      apapprover1_lvl
      apapprover1_mgremailaddress
      apapprover1_mgrid
      apapprover1_name
      apapprover1_status
      apapprover2_comments
      apapprover2_dateOfApproval
      apapprover2_dateOfEntry
      apapprover2_emailAddr
      apapprover2_id
      apapprover2_isdelegator
      apapprover2_lvl
      apapprover2_mgremailaddress
      apapprover2_mgrid
      apapprover2_name
      apapprover2_status
      apapprover3_comments
      apapprover3_dateOfApproval
      apapprover3_dateOfEntry
      apapprover3_emailAddr
      apapprover3_id
      apapprover3_isdelegator
      apapprover3_lvl
      apapprover3_mgremailaddress
      apapprover3_mgrid
      apapprover3_name
      apapprover3_status
      apapprover4_comments
      apapprover4_dateOfApproval
      apapprover4_dateOfEntry
      apapprover4_emailAddr
      apapprover4_id
      apapprover4_isdelegator
      apapprover4_lvl
      apapprover4_mgremailaddress
      apapprover4_mgrid
      apapprover4_name
      apapprover4_status
      apapprover5_comments
      apapprover5_dateOfApproval
      apapprover5_dateOfEntry
      apapprover5_emailAddr
      apapprover5_id
      apapprover5_isdelegator
      apapprover5_lvl
      apapprover5_mgremailaddress
      apapprover5_mgrid
      apapprover5_name
      apapprover5_status
      apresult
      arRequestSubmittedDate
      arRequestorEmailId
      arRequestorId
      arSAPDocNo
      arSAPErrorMessage
      ar_doc_created_at
      ar_doc_created_by
      ar_doc_created_on
      ar_pay_term
      ar_pay_term_desc
      ar_posting_period
      ar_posting_year
      arapprover1_comments
      arapprover1_dateOfApproval
      arapprover1_dateOfEntry
      arapprover1_emailAddr
      arapprover1_id
      arapprover1_isdelegator
      arapprover1_lvl
      arapprover1_mgremailaddress
      arapprover1_mgrid
      arapprover1_name
      arapprover1_status
      arapprover2_comments
      arapprover2_dateOfApproval
      arapprover2_dateOfEntry
      arapprover2_emailAddr
      arapprover2_id
      arapprover2_isdelegator
      arapprover2_lvl
      arapprover2_mgremailaddress
      arapprover2_mgrid
      arapprover2_name
      arapprover2_status
      arapprover3_comments
      arapprover3_dateOfApproval
      arapprover3_dateOfEntry
      arapprover3_emailAddr
      arapprover3_id
      arapprover3_isdelegator
      arapprover3_lvl
      arapprover3_mgremailaddress
      arapprover3_mgrid
      arapprover3_name
      arapprover3_status
      arapprover4_comments
      arapprover4_dateOfApproval
      arapprover4_dateOfEntry
      arapprover4_emailAddr
      arapprover4_id
      arapprover4_isdelegator
      arapprover4_lvl
      arapprover4_mgremailaddress
      arapprover4_mgrid
      arapprover4_name
      arapprover4_status
      arapprover5_comments
      arapprover5_dateOfApproval
      arapprover5_dateOfEntry
      arapprover5_emailAddr
      arapprover5_id
      arapprover5_isdelegator
      arapprover5_lvl
      arapprover5_mgremailaddress
      arapprover5_mgrid
      arapprover5_name
      arapprover5_status
      arresult
      currAPApproverLVL
      currARApproverLVL
      currencyType
      dig_sign
      exchangeRate
      id
      invoiceDate
      invoiceNo
      postingDate
      receivingCompanyCode
      recvBusinessPlace
      recvCountryCode
      recvCurrAmt
      requestCompletionDate
      requestDate
      requestDescription
      requestId
      requestType
      sAPFailureMessage
      sendBusinessPlace
      sendCountryCode
      sendCurrAmt
      sendingCompanyCode
      stateName
      status
      taskToken
      totalAPApproverLVL
      totalARApproverLVL
      totalAmt
      uniq_doc
      
    }
  }
`;
export const getCommXchargeDReportdashboardByRequestId2 = /* GraphQL */ `
  query GetCommXchargeDReportdashboardByRequestId2(
    $count: Int
    $nextToken: String
    $requestId: Int
  ) {
    getCommXchargeDReportdashboardByRequestId2(
      count: $count
      nextToken: $nextToken
      requestId: $requestId
    ) {
      commXchargeDReportdashboards {
        actionAwaiting
        actionAwaitingEmailAddr
        actionType
        apRequestSubmittedDate
        apRequestorEmailId
        apRequestorId
        apRequestor_dateOfEntry
        apRequestor_mgremailaddress
        apRequestor_mgrid
        apSAPDocNo
        apSAPErrorMessage
        ap_doc_created_at
        ap_doc_created_by
        ap_doc_created_on
        ap_pay_term
        ap_pay_term_desc
        ap_posting_period
        ap_posting_year
        apapprover1_comments
        apapprover1_dateOfApproval
        apapprover1_dateOfEntry
        apapprover1_emailAddr
        apapprover1_id
        apapprover1_isdelegator
        apapprover1_lvl
        apapprover1_mgremailaddress
        apapprover1_mgrid
        apapprover1_name
        apapprover1_status
        apapprover2_comments
        apapprover2_dateOfApproval
        apapprover2_dateOfEntry
        apapprover2_emailAddr
        apapprover2_id
        apapprover2_isdelegator
        apapprover2_lvl
        apapprover2_mgremailaddress
        apapprover2_mgrid
        apapprover2_name
        apapprover2_status
        apapprover3_comments
        apapprover3_dateOfApproval
        apapprover3_dateOfEntry
        apapprover3_emailAddr
        apapprover3_id
        apapprover3_isdelegator
        apapprover3_lvl
        apapprover3_mgremailaddress
        apapprover3_mgrid
        apapprover3_name
        apapprover3_status
        apapprover4_comments
        apapprover4_dateOfApproval
        apapprover4_dateOfEntry
        apapprover4_emailAddr
        apapprover4_id
        apapprover4_isdelegator
        apapprover4_lvl
        apapprover4_mgremailaddress
        apapprover4_mgrid
        apapprover4_name
        apapprover4_status
        apapprover5_comments
        apapprover5_dateOfApproval
        apapprover5_dateOfEntry
        apapprover5_emailAddr
        apapprover5_id
        apapprover5_isdelegator
        apapprover5_lvl
        apapprover5_mgremailaddress
        apapprover5_mgrid
        apapprover5_name
        apapprover5_status
        apresult
        arRequestSubmittedDate
        arRequestorEmailId
        arRequestorId
        arSAPDocNo
        arSAPErrorMessage
        ar_doc_created_at
        ar_doc_created_by
        ar_doc_created_on
        ar_pay_term
        ar_pay_term_desc
        ar_posting_period
        ar_posting_year
        arapprover1_comments
        arapprover1_dateOfApproval
        arapprover1_dateOfEntry
        arapprover1_emailAddr
        arapprover1_id
        arapprover1_isdelegator
        arapprover1_lvl
        arapprover1_mgremailaddress
        arapprover1_mgrid
        arapprover1_name
        arapprover1_status
        arapprover2_comments
        arapprover2_dateOfApproval
        arapprover2_dateOfEntry
        arapprover2_emailAddr
        arapprover2_id
        arapprover2_isdelegator
        arapprover2_lvl
        arapprover2_mgremailaddress
        arapprover2_mgrid
        arapprover2_name
        arapprover2_status
        arapprover3_comments
        arapprover3_dateOfApproval
        arapprover3_dateOfEntry
        arapprover3_emailAddr
        arapprover3_id
        arapprover3_isdelegator
        arapprover3_lvl
        arapprover3_mgremailaddress
        arapprover3_mgrid
        arapprover3_name
        arapprover3_status
        arapprover4_comments
        arapprover4_dateOfApproval
        arapprover4_dateOfEntry
        arapprover4_emailAddr
        arapprover4_id
        arapprover4_isdelegator
        arapprover4_lvl
        arapprover4_mgremailaddress
        arapprover4_mgrid
        arapprover4_name
        arapprover4_status
        arapprover5_comments
        arapprover5_dateOfApproval
        arapprover5_dateOfEntry
        arapprover5_emailAddr
        arapprover5_id
        arapprover5_isdelegator
        arapprover5_lvl
        arapprover5_mgremailaddress
        arapprover5_mgrid
        arapprover5_name
        arapprover5_status
        arresult
        currAPApproverLVL
        currARApproverLVL
        currencyType
        dig_sign
        exchangeRate
        id
        invoiceDate
        invoiceNo
        postingDate
        receivingCompanyCode
        recvBusinessPlace
        recvCountryCode
        recvCurrAmt
        requestCompletionDate
        requestDate
        requestDescription
        requestId
        requestType
        sAPFailureMessage
        sendBusinessPlace
        sendCountryCode
        sendCurrAmt
        sendingCompanyCode
        stateName
        status
        taskToken
        totalAPApproverLVL
        totalARApproverLVL
        totalAmt
        uniq_doc
        
      }
      nextToken
      
    }
  }
`;
export const getCommXchargeDSkb1 = /* GraphQL */ `
  query GetCommXchargeDSkb1($id: String!) {
    getCommXchargeDSkb1(id: $id) {
      bukrs
      dtype
      id
      mandt
      saknr
      txt20
      
    }
  }
`;
export const getCommXchargeDT001 = /* GraphQL */ `
  query GetCommXchargeDT001($id: String!) {
    getCommXchargeDT001(id: $id) {
      bukrs
      butxt
      city1
      country
      dtype
      house_num1
      id
      land1
      mandt
      region
      street
      vbList
      waers
      wfvar
      
    }
  }
`;
export const getCommXchargeDT006A = /* GraphQL */ `
  query GetCommXchargeDT006A($id: String!) {
    getCommXchargeDT006A(id: $id) {
      dtype
      id
      mandt
      msehi
      msehl
      mseht
      spras
      
    }
  }
`;
export const getCommXchargeDT007A = /* GraphQL */ `
  query GetCommXchargeDT007A($id: String!) {
    getCommXchargeDT007A(id: $id) {
      dtype
      id
      kalsm
      land1
      mandt
      mwart
      mwskz
      text1
      xinact
      
    }
  }
`;
export const getCommXchargeDTcurc = /* GraphQL */ `
  query GetCommXchargeDTcurc($id: String!) {
    getCommXchargeDTcurc(id: $id) {
      currdec
      id
      mandt
      waers
      
    }
  }
`;
export const getCommXchargeDTradingpartner = /* GraphQL */ `
  query GetCommXchargeDTradingpartner($id: String!) {
    getCommXchargeDTradingpartner(id: $id) {
      companydesc
      fitcode
      id
      marscode
      tradingpartner
      
    }
  }
`;
export const getCommXchargeDUsr01 = /* GraphQL */ `
  query GetCommXchargeDUsr01($id: String!) {
    getCommXchargeDUsr01(id: $id) {
      bname
      dcpfm
      decDesc
      dtype
      id
      mandt
      
    }
  }
`;
export const getCommXchargeDZfinApproval = /* GraphQL */ `
  query GetCommXchargeDZfinApproval($id: String!) {
    getCommXchargeDZfinApproval(id: $id) {
      butxt
      company_code
      dtype
      emp_id
      emp_name
      id
      land1
      level1_id
      level1_name
      level2_id
      level2_name
      level3_id
      level3_name
      level4_id
      level4_name
      level5_id
      level5_name
      mandt
      rel_grp
      
    }
  }
`;
export const getCrossChargeForm = /* GraphQL */ `
  query GetCrossChargeForm($id: String!) {
    getCrossChargeForm(id: $id) {
      actionAwaiting
      actionAwaitingEmailAddr
      actionType
      adminActionLvl_AP
      adminActionLvl_AR
      adminComments
      adminEmailAddress
      adminUserId
      adminsubmitedDate
      apApprovers {
        actualAPApprovers
        apRequestor
        apapprover1_comments
        apapprover1_dateOfApproval
        apapprover1_dateOfEntry
        apapprover1_emailAddr
        apapprover1_id
        apapprover1_isdelegator
        apapprover1_lvl
        apapprover1_mgremailaddress
        apapprover1_mgrid
        apapprover1_name
        apapprover1_status
        apapprover2_comments
        apapprover2_dateOfApproval
        apapprover2_dateOfEntry
        apapprover2_emailAddr
        apapprover2_id
        apapprover2_isdelegator
        apapprover2_lvl
        apapprover2_mgremailaddress
        apapprover2_mgrid
        apapprover2_name
        apapprover2_status
        apapprover3_comments
        apapprover3_dateOfApproval
        apapprover3_dateOfEntry
        apapprover3_emailAddr
        apapprover3_id
        apapprover3_isdelegator
        apapprover3_lvl
        apapprover3_mgremailaddress
        apapprover3_mgrid
        apapprover3_name
        apapprover3_status
        apapprover4_comments
        apapprover4_dateOfApproval
        apapprover4_dateOfEntry
        apapprover4_emailAddr
        apapprover4_id
        apapprover4_isdelegator
        apapprover4_lvl
        apapprover4_mgremailaddress
        apapprover4_mgrid
        apapprover4_name
        apapprover4_status
        apapprover5_comments
        apapprover5_dateOfApproval
        apapprover5_dateOfEntry
        apapprover5_emailAddr
        apapprover5_id
        apapprover5_isdelegator
        apapprover5_lvl
        apapprover5_mgremailaddress
        apapprover5_mgrid
        apapprover5_name
        apapprover5_status
        dType
        existAPApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      apLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        
      }
      apRequestSubmittedDate
      apRequestor
      apRequestorEmailAddress
      apRequestor_comments
      apRequestor_dateOfEntry
      apRequestor_mgremailaddress
      apRequestor_mgrid
      apSAPDocNo
      ap_doc_created_at
      ap_doc_created_by
      ap_doc_created_on
      ap_pay_term
      ap_pay_term_desc
      ap_posting_period
      ap_posting_year
      apapSAPErrorMessage
      apresult
      arApprovers {
        actualARApprovers
        arRequestor
        arapprover1_comments
        arapprover1_dateOfApproval
        arapprover1_dateOfEntry
        arapprover1_emailAddr
        arapprover1_id
        arapprover1_isdelegator
        arapprover1_lvl
        arapprover1_mgremailaddress
        arapprover1_mgrid
        arapprover1_name
        arapprover1_status
        arapprover2_comments
        arapprover2_dateOfApproval
        arapprover2_dateOfEntry
        arapprover2_emailAddr
        arapprover2_id
        arapprover2_isdelegator
        arapprover2_lvl
        arapprover2_mgremailaddress
        arapprover2_mgrid
        arapprover2_name
        arapprover2_status
        arapprover3_comments
        arapprover3_dateOfApproval
        arapprover3_dateOfEntry
        arapprover3_emailAddr
        arapprover3_id
        arapprover3_isdelegator
        arapprover3_lvl
        arapprover3_mgremailaddress
        arapprover3_mgrid
        arapprover3_name
        arapprover3_status
        arapprover4_comments
        arapprover4_dateOfApproval
        arapprover4_dateOfEntry
        arapprover4_emailAddr
        arapprover4_id
        arapprover4_isdelegator
        arapprover4_lvl
        arapprover4_mgremailaddress
        arapprover4_mgrid
        arapprover4_name
        arapprover4_status
        arapprover5_comments
        arapprover5_dateOfApproval
        arapprover5_dateOfEntry
        arapprover5_emailAddr
        arapprover5_id
        arapprover5_isdelegator
        arapprover5_lvl
        arapprover5_mgremailaddress
        arapprover5_mgrid
        arapprover5_name
        arapprover5_status
        dType
        existARApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      arLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        
      }
      arRequestSubmittedDate
      arRequestor
      arRequestorEmailAddress
      arRequestor_comments
      arSAPDocNo
      arSAPErrorMessage
      ar_doc_created_at
      ar_doc_created_by
      ar_doc_created_on
      ar_pay_term
      ar_pay_term_desc
      ar_posting_period
      ar_posting_year
      arresult
      attachments {
        createdAt
        createdBy
        fileName
        id
        index
        
      }
      commentHistoryItems {
        comment
        mailId
        time
        
      }
      currAPApproverLVL
      currARApproverLVL
      currency
      dig_sign
      exchangeRate
      executionID
      formStatus
      id
      invoiceDate
      invoiceNo
      isadminAction
      postingDate
      receivingCompanyCode
      recvBusinessPlace
      recvCountryCode
      recvCurrAmt
      requestCompletionDate
      requestDate
      requestDescription
      requestId
      requestType
      sapfailureMessage
      sendBusinessPlace
      sendCountryCode
      sendCurrAmt
      sendingCompanyCode
      stateName
      totalAPApproverLVL
      totalARApproverLVL
      totalAmt
      uniq_doc
      
    }
  }
`;
export const getElasticAccessToken = /* GraphQL */ `
  query GetElasticAccessToken($input: String) {
    getElasticAccessToken(input: $input) {
      access_token
      expires_in
      refresh_token
      type
      
    }
  }
`;
export const getExecutionIDbyRequestID = /* GraphQL */ `
  query GetExecutionIDbyRequestID($requestId: Int) {
    getExecutionIDbyRequestID(requestId: $requestId)
  }
`;
export const listCommDXchargeTwews = /* GraphQL */ `
  query ListCommDXchargeTwews(
    $filter: TableCommDXchargeTwewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommDXchargeTwews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dtype
        ewbez
        extwg
        id
        mandt
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDAgrusers = /* GraphQL */ `
  query ListCommXchargeDAgrusers(
    $filter: TableCommXchargeDAgrusersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDAgrusers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cclist
        change_dat
        change_tim
        dtype
        from_dat
        id
        mandt
        to_dat
        uname
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDArApprovers = /* GraphQL */ `
  query ListCommXchargeDArApprovers(
    $filter: TableCommXchargeDArApproversFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDArApprovers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        actualARApprovers
        arRequestor
        arapprover1_comments
        arapprover1_dateOfApproval
        arapprover1_dateOfEntry
        arapprover1_emailAddr
        arapprover1_id
        arapprover1_isdelegator
        arapprover1_lvl
        arapprover1_mgremailaddress
        arapprover1_mgrid
        arapprover1_name
        arapprover1_status
        arapprover2_comments
        arapprover2_dateOfApproval
        arapprover2_dateOfEntry
        arapprover2_emailAddr
        arapprover2_id
        arapprover2_isdelegator
        arapprover2_lvl
        arapprover2_mgremailaddress
        arapprover2_mgrid
        arapprover2_name
        arapprover2_status
        arapprover3_comments
        arapprover3_dateOfApproval
        arapprover3_dateOfEntry
        arapprover3_emailAddr
        arapprover3_id
        arapprover3_isdelegator
        arapprover3_lvl
        arapprover3_mgremailaddress
        arapprover3_mgrid
        arapprover3_name
        arapprover3_status
        arapprover4_comments
        arapprover4_dateOfApproval
        arapprover4_dateOfEntry
        arapprover4_emailAddr
        arapprover4_id
        arapprover4_isdelegator
        arapprover4_lvl
        arapprover4_mgremailaddress
        arapprover4_mgrid
        arapprover4_name
        arapprover4_status
        arapprover5_comments
        arapprover5_dateOfApproval
        arapprover5_dateOfEntry
        arapprover5_emailAddr
        arapprover5_id
        arapprover5_isdelegator
        arapprover5_lvl
        arapprover5_mgremailaddress
        arapprover5_mgrid
        arapprover5_name
        arapprover5_status
        dType
        existARApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDCepcs = /* GraphQL */ `
  query ListCommXchargeDCepcs(
    $filter: TableCommXchargeDCepcFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDCepcs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bukrs
        dataab
        databi
        dtype
        ersda
        id
        kokrs
        ltext
        mandt
        prctr
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDCoas = /* GraphQL */ `
  query ListCommXchargeDCoas(
    $filter: TableCommXchargeDCoasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDCoas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        aufnr
        bukrs
        dtype
        id
        ktext
        mandt
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDCsks = /* GraphQL */ `
  query ListCommXchargeDCsks(
    $filter: TableCommXchargeDCsksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDCsks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bukrs
        dataab
        databi
        dtype
        ersda
        id
        kokrs
        kostl
        ltext
        mandt
        prctr
        txjcd
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDHrusd2S = /* GraphQL */ `
  query ListCommXchargeDHrusd2S(
    $filter: TableCommXchargeDHrusd2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDHrusd2S(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        active
        begda
        dtype
        endda
        id
        mandt
        rep_name
        us_name
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDJ1bbranches = /* GraphQL */ `
  query ListCommXchargeDJ1bbranches(
    $filter: TableCommXchargeDJ1bbranchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDJ1bbranches(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        branch
        bukrs
        cgc_branch
        dtype
        id
        mandt
        name
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDKNA1S = /* GraphQL */ `
  query ListCommXchargeDKNA1S(
    $filter: TableCommXchargeDKNA1FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDKNA1S(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dtype
        id
        kunnr
        mandt
        vbund
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDKnb1S = /* GraphQL */ `
  query ListCommXchargeDKnb1S(
    $filter: TableCommXchargeDKnb1FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDKnb1S(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bukrs
        dtype
        id
        kunnr
        mandt
        name1
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDLFA1s = /* GraphQL */ `
  query ListCommXchargeDLFA1s(
    $filter: TableCommXchargeDLFA1FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDLFA1s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dtype
        erdat
        id
        lifnr
        loevm
        mandt
        vbund
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDLFB1S = /* GraphQL */ `
  query ListCommXchargeDLFB1S(
    $filter: TableCommXchargeDLFB1FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDLFB1S(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bukrs
        dtype
        id
        lifnr
        mandt
        name1
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDMaras = /* GraphQL */ `
  query ListCommXchargeDMaras(
    $filter: TableCommXchargeDMaraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDMaras(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bismt
        dtype
        ersda
        extwg
        id
        laeda
        maktx
        mandt
        matkl
        matnr
        mtart
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDReportdashboards = /* GraphQL */ `
  query ListCommXchargeDReportdashboards(
    $filter: TableCommXchargeDReportdashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDReportdashboards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        actionAwaiting
        actionAwaitingEmailAddr
        actionType
        apRequestSubmittedDate
        apRequestorEmailId
        apRequestorId
        apRequestor_dateOfEntry
        apRequestor_mgremailaddress
        apRequestor_mgrid
        apSAPDocNo
        apSAPErrorMessage
        ap_doc_created_at
        ap_doc_created_by
        ap_doc_created_on
        ap_pay_term
        ap_pay_term_desc
        ap_posting_period
        ap_posting_year
        apapprover1_comments
        apapprover1_dateOfApproval
        apapprover1_dateOfEntry
        apapprover1_emailAddr
        apapprover1_id
        apapprover1_isdelegator
        apapprover1_lvl
        apapprover1_mgremailaddress
        apapprover1_mgrid
        apapprover1_name
        apapprover1_status
        apapprover2_comments
        apapprover2_dateOfApproval
        apapprover2_dateOfEntry
        apapprover2_emailAddr
        apapprover2_id
        apapprover2_isdelegator
        apapprover2_lvl
        apapprover2_mgremailaddress
        apapprover2_mgrid
        apapprover2_name
        apapprover2_status
        apapprover3_comments
        apapprover3_dateOfApproval
        apapprover3_dateOfEntry
        apapprover3_emailAddr
        apapprover3_id
        apapprover3_isdelegator
        apapprover3_lvl
        apapprover3_mgremailaddress
        apapprover3_mgrid
        apapprover3_name
        apapprover3_status
        apapprover4_comments
        apapprover4_dateOfApproval
        apapprover4_dateOfEntry
        apapprover4_emailAddr
        apapprover4_id
        apapprover4_isdelegator
        apapprover4_lvl
        apapprover4_mgremailaddress
        apapprover4_mgrid
        apapprover4_name
        apapprover4_status
        apapprover5_comments
        apapprover5_dateOfApproval
        apapprover5_dateOfEntry
        apapprover5_emailAddr
        apapprover5_id
        apapprover5_isdelegator
        apapprover5_lvl
        apapprover5_mgremailaddress
        apapprover5_mgrid
        apapprover5_name
        apapprover5_status
        apresult
        arRequestSubmittedDate
        arRequestorEmailId
        arRequestorId
        arSAPDocNo
        arSAPErrorMessage
        ar_doc_created_at
        ar_doc_created_by
        ar_doc_created_on
        ar_pay_term
        ar_pay_term_desc
        ar_posting_period
        ar_posting_year
        arapprover1_comments
        arapprover1_dateOfApproval
        arapprover1_dateOfEntry
        arapprover1_emailAddr
        arapprover1_id
        arapprover1_isdelegator
        arapprover1_lvl
        arapprover1_mgremailaddress
        arapprover1_mgrid
        arapprover1_name
        arapprover1_status
        arapprover2_comments
        arapprover2_dateOfApproval
        arapprover2_dateOfEntry
        arapprover2_emailAddr
        arapprover2_id
        arapprover2_isdelegator
        arapprover2_lvl
        arapprover2_mgremailaddress
        arapprover2_mgrid
        arapprover2_name
        arapprover2_status
        arapprover3_comments
        arapprover3_dateOfApproval
        arapprover3_dateOfEntry
        arapprover3_emailAddr
        arapprover3_id
        arapprover3_isdelegator
        arapprover3_lvl
        arapprover3_mgremailaddress
        arapprover3_mgrid
        arapprover3_name
        arapprover3_status
        arapprover4_comments
        arapprover4_dateOfApproval
        arapprover4_dateOfEntry
        arapprover4_emailAddr
        arapprover4_id
        arapprover4_isdelegator
        arapprover4_lvl
        arapprover4_mgremailaddress
        arapprover4_mgrid
        arapprover4_name
        arapprover4_status
        arapprover5_comments
        arapprover5_dateOfApproval
        arapprover5_dateOfEntry
        arapprover5_emailAddr
        arapprover5_id
        arapprover5_isdelegator
        arapprover5_lvl
        arapprover5_mgremailaddress
        arapprover5_mgrid
        arapprover5_name
        arapprover5_status
        arresult
        currAPApproverLVL
        currARApproverLVL
        currencyType
        dig_sign
        exchangeRate
        id
        invoiceDate
        invoiceNo
        postingDate
        receivingCompanyCode
        recvBusinessPlace
        recvCountryCode
        recvCurrAmt
        requestCompletionDate
        requestDate
        requestDescription
        requestId
        requestType
        sAPFailureMessage
        sendBusinessPlace
        sendCountryCode
        sendCurrAmt
        sendingCompanyCode
        stateName
        status
        taskToken
        totalAPApproverLVL
        totalARApproverLVL
        totalAmt
        uniq_doc
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDSkb1S = /* GraphQL */ `
  query ListCommXchargeDSkb1S(
    $filter: TableCommXchargeDSkb1FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDSkb1S(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bukrs
        dtype
        id
        mandt
        saknr
        txt20
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDT001S = /* GraphQL */ `
  query ListCommXchargeDT001S(
    $filter: TableCommXchargeDT001FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDT001S(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bukrs
        butxt
        city1
        country
        dtype
        house_num1
        id
        land1
        mandt
        region
        street
        vbList
        waers
        wfvar
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDT006AS = /* GraphQL */ `
  query ListCommXchargeDT006AS(
    $filter: TableCommXchargeDT006AFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDT006AS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dtype
        id
        mandt
        msehi
        msehl
        mseht
        spras
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDT007AS = /* GraphQL */ `
  query ListCommXchargeDT007AS(
    $filter: TableCommXchargeDT007AFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDT007AS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dtype
        id
        kalsm
        land1
        mandt
        mwart
        mwskz
        text1
        xinact
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDTcurcs = /* GraphQL */ `
  query ListCommXchargeDTcurcs(
    $filter: TableCommXchargeDTcurcFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDTcurcs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        currdec
        id
        mandt
        waers
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDTradingpartners = /* GraphQL */ `
  query ListCommXchargeDTradingpartners(
    $filter: TableCommXchargeDTradingpartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDTradingpartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        companydesc
        fitcode
        id
        marscode
        tradingpartner
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDUsr01s = /* GraphQL */ `
  query ListCommXchargeDUsr01s(
    $filter: TableCommXchargeDUsr01FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDUsr01s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bname
        dcpfm
        decDesc
        dtype
        id
        mandt
        
      }
      nextToken
      
    }
  }
`;
export const listCommXchargeDZfinApprovals = /* GraphQL */ `
  query ListCommXchargeDZfinApprovals(
    $filter: TableCommXchargeDZfinApprovalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommXchargeDZfinApprovals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        butxt
        company_code
        dtype
        emp_id
        emp_name
        id
        land1
        level1_id
        level1_name
        level2_id
        level2_name
        level3_id
        level3_name
        level4_id
        level4_name
        level5_id
        level5_name
        mandt
        rel_grp
        
      }
      nextToken
      
    }
  }
`;
