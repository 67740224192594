import React from 'react'

const HorizontalLine = (props) => {
  return (
    <hr 
      className={`cc-horizontal-line ${props?.className}`}
    />
  )
}

export default HorizontalLine