import React, { useState, useContext } from 'react'
import { message, Modal, Spin, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faUpload } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import momentTimeZone from 'moment-timezone';

import Heading from '../HOC/Heading'
import HorizontalLine from '../HOC/HorizontalLine'
import { API, Auth } from 'aws-amplify'
import { allowAR, DeleteDocs, enableEditForAp, formDisable, getDownloadPresignedUrl, splitWithPipe } from '../helper'
import ContextData from '../context/ContextData'
import { getSupportingDocColumns } from '../columns';
import {InfoCircleOutlined} from '@ant-design/icons';

const SupportingDoc = (props) => {

    const { supportingDocs, setSupportingDocs, formID, formData } = props;

    const [messageApi, contextHolder] = message.useMessage();
    const contextValue = useContext(ContextData);
    const { currentUser } = contextValue;

    const [isUploading, setIsUploading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const fileAllowableList = splitWithPipe(process.env.REACT_APP_File_Extensions_Allowed_For_Upload);
    // const formDisableCondition = formDisable(props?.formStatus);
    const formDisableCondition = !( allowAR(props?.formStatus, formData?.arRequestor, props?.currentUser) )

    console.log("current time", momentTimeZone().tz('America/New_York').format('MM/DD/YYYY hh:mm A z'));
    console.log("current time", momentTimeZone().tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm A z'));

    console.log(moment().format('HH:mm')); // 24 hour format 
    console.log(moment().format('hh:mm')); // 12 hour format
    console.log(moment().format('hh:mm A'));
    console.log(moment().format('hh:mm a'));
    console.log("Now: " + moment().format('hh:mm A'));

    const supportingDocColumns = [
        {
            title: 'Documents',
            dataIndex: 'fileName',
            key: 'fileName',
            width: '30%',
            className: 'doc-column',
            render: (text, record) => {
                return <span className='cc-link' onClick={() => { getDownloadPresignedUrl(formID,text, messageApi) }}>{text}</span>
            }
        },
        ...getSupportingDocColumns()
    ]
    

    const handleSupportingDocUpload = async (e) => {
        console.log("supporting files", e.target.files);

        const fileName = e.target.files[0] && e.target.files[0].name;
        const fileToUpload = e.target.files[0];
        let metadata = e.target.files[0] && e.target.files[0].type;
        const fileExtension = fileName?.split(".")?.pop();

        if (fileExtension.toLowerCase() === 'msg') {
            metadata = 'application/vnd.ms-outlook';
        }

        console.log("fileName", fileName);
        console.log("fileToUpload", fileToUpload);
        console.log("metadata", metadata);
        console.log("fileExtension", fileExtension);

        const key = 'updatable';

        const PushFileToS3 = (presignedUploadUrl, file) => {

            fetch(presignedUploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': metadata
                },
                body: file
            }).then((response) => {
                console.log("file upload res", response)
                console.log("file upload res status", response.status)
                if (response.status === 200) {
                    setIsUploading(false);
                    messageApi.open({
                        key,
                        type: 'success',
                        content: 'File uploaded successfully!',
                        duration: 2,
                    })
                    const newSupportingDoc = {
                        'id': supportingDocs.length + 1,
                        'index': supportingDocs.length + 1,
                        'createdBy': currentUser?.userID?.toUpperCase(),
                        'createdAt': momentTimeZone().tz('America/New_York').format('MM/DD/YYYY hh:mm A z'),
                        'fileName': fileName
                    }
                    setSupportingDocs([...supportingDocs, newSupportingDoc]);
                } else {
                    setIsUploading(false);
                    messageApi.open({
                        key,
                        type: 'error',
                        content: 'There is an error while uploading the file.',
                        className: 'message-style',
                        duration: 5
                    });
                }
            }).catch((err) => {
                console.log("file upload err", err)
                setIsUploading(false);
                messageApi.open({
                    key,
                    type: 'error',
                    content: 'There is an error while uploading the file.',
                    className: 'message-style',
                    duration: 5
                });
            })
        }


        if (supportingDocs?.length < 5 || !supportingDocs) {
            if ((fileAllowableList.includes(fileExtension.toLowerCase())) && (fileToUpload.size <= 10485760)) {
                setIsUploading(true);

                messageApi.open({
                    key,
                    type: 'loading',
                    content: 'Uploading file...',
                });


                const session = await Auth.currentSession();
                const token = session.getIdToken().getJwtToken();

                API
                    .post('CrossChargePresignedURL', '', {
                        headers: {
                            'Content-Type': metadata,
                            "file-name": `${formID}/${fileName}`,
                            Authorization: `Bearer ${token}`
                        },
                        body: fileToUpload
                    }).then((response) => {
                        console.log("presigned res", response);
                        if (response) {
                            PushFileToS3(response, fileToUpload);

                        } else {
                            setIsUploading(false);
                            messageApi.open({
                                key,
                                type: 'error',
                                content: 'There is an error while getting presigned token and uploading the file.',
                                className: 'message-style',
                                duration: 5
                            });
                        }

                    }).catch((err) => {
                        console.log("presigned err", err);
                        setIsUploading(false);
                        messageApi.open({
                            key,
                            type: 'error',
                            content: 'There is an error while getting presigned token and uploading the file.',
                            className: 'message-style',
                            duration: 5
                        });
                    })



            } else {
                Modal.error({
                    className: 'info-modal',
                    title: `Please upload file of type ${new Intl.ListFormat('en', { style: 'long', type: 'disjunction' }).format(fileAllowableList?.map(l => `.${l}`))} having size upto 10MB`,
                    okButtonProps: { "className": "modal-btn" }
                });
            }
        } else {
            Modal.error({
                className: 'info-modal',
                title: `Maximum number of files uploadable is five`,
                okButtonProps: { "className": "modal-btn" }
            });
        }

    }

    


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };

    console.log("supportingDoc", supportingDocs);

    const fileUploadableString = new Intl.ListFormat('en', { style: 'short', type: 'conjunction' }).format(fileAllowableList?.map(l => `.${l}`))

    return (
        <div className={`supportingDoc-cnt ` + (!props?.formData?.commentHistoryItems && 'adjust-width-doc-cnt')}>
            {contextHolder}
            <Heading
                heading='Supporting Documents'
            />
            <HorizontalLine />

            {
                (!formDisableCondition || enableEditForAp(props?.formStatus, props?.apRequestor, props?.currentUser ) )&&
                <>
                    <input type='file' id='supportingDoc-file' style={{ display: 'none' }}
                        onChange={handleSupportingDocUpload}
                    />
                    <label
                        htmlFor='supportingDoc-file'
                        className='ortho-btn'
                    >
                        <FontAwesomeIcon icon={faUpload} className='cc-icons' style={{ marginRight: "10px", color: "white", opacity: "1" }} />
                        Upload a file
                    </label>
                    <Tooltip title={`Allowed file types: ${fileUploadableString}. Max 5 files, upto 10MB each`}>
                                            
                        <InfoCircleOutlined 
                            className='cc-icons'
                            style={{marginLeft:"10px"}}
                        />
                    </Tooltip>

                </>
            }

            {
                (!formDisableCondition || enableEditForAp(props?.formStatus, props?.apRequestor, props?.currentUser) )&& supportingDocs?.length > 0 &&
                <div className='cc-item-add-dlt-cnt cc-doc-dlt-cnt'>

                    <FontAwesomeIcon icon={faMinus}
                        title='Delete selected items'
                        className='cc-icons cc-minus-icon'
                        onClick={() => DeleteDocs(supportingDocs, selectedRowKeys, setSupportingDocs)}
                    />
                </div>
            }

            {
                supportingDocs?.length > 0 &&
                <Table
                    id="supportingDoc-table"
                    size="small"
                    className='supportingDocTable'
                    columns={supportingDocColumns}
                    dataSource={supportingDocs}
                    rowKey='fileName'
                    pagination={false}
                    bordered
                    rowSelection={rowSelection}
                    loading={{ indicator: <div><Spin /></div>, spinning: isUploading }}
                />
            }






        </div>
    )
}

export default SupportingDoc