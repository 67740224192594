import React from 'react'
import { formatDate, TBP } from '../helper';
import LabelInfoPair from './LabelInfoPair'

const SendingCmpnyInfo = (props) => {

    const { reviewData } = props;


    return (
        <div className="cc-card-body">
            <LabelInfoPair
                label='Company Code'
                information={reviewData?.sendingCompanyCode}
            />

            {   
                reviewData?.sendBusinessPlace &&
                    <LabelInfoPair
                        label='Business Place'
                        information={reviewData?.sendBusinessPlace}
                    />
            }

            <LabelInfoPair
                label='Invoice Date'
                information={reviewData?.invoiceDate ? formatDate(reviewData?.invoiceDate) : TBP}
                note={reviewData?.invoiceDate ? false : true}
            />

            <div className='d-flex'>
                <LabelInfoPair
                    label='Posting Date'
                    information={reviewData?.postingDate ? formatDate(reviewData?.postingDate) : TBP}
                    note={reviewData?.postingDate ? false : true}
                />
                
                {
                    reviewData?.postingDate &&
                    <>
                        <LabelInfoPair
                            label='Period'
                            information={reviewData?.ar_posting_period}
                            style={{marginLeft:"15px"}}
                        />
                        
                        <LabelInfoPair
                            label='Year'
                            information={reviewData?.ar_posting_year}
                            style={{marginLeft:"10px"}}
                        />
                    </>
                }
                
            </div>

            <LabelInfoPair
                label='AR Document'
                information={reviewData?.arSAPDocNo ? reviewData?.arSAPDocNo : TBP }
                note={reviewData?.arSAPDocNo ? false : true}
            />

            <LabelInfoPair
                label='AR Reference'
                information={reviewData?.apSAPDocNo ? reviewData?.apSAPDocNo : TBP }
                note={reviewData?.apSAPDocNo ? false : true}
            />

            <LabelInfoPair
                label='AR Requestor'
                information={reviewData?.arRequestor}
            />

        </div>
    )
}

export default SendingCmpnyInfo