import React from 'react'
import { Auth } from 'aws-amplify';
import logo from '../QO_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import RequestOptions from './dashboardComponents/RequestOptions';
import EnvDetector from './EnvDetector';

const Header = (props) => {

    const {setIsLoading} = props;


    const handleLogout = async () => {
        await Auth.signOut();
    }

    const items = [
        {
            key: 'user',
            label: (
                <div onClick={handleLogout}  className='new-req-option' >
                Sign Out
                <FontAwesomeIcon icon={faRightFromBracket}
                    className='cc-icons cc-icons-grey'
                    style={{ marginLeft: "20px" }}
                />
                </div>
            ),
        },
    ]


    return (
        <div className='header-cnt-outer'>
            <div className='header-cnt-inner'>

                {
                    process.env.REACT_APP_ENV && ['DEV', 'QA'].includes(process.env.REACT_APP_ENV) &&
                    <EnvDetector 
                        env = {process.env.REACT_APP_ENV}
                    />
                }
                
                <img className='logo' src={logo} alt='QuidelOrtho'/>
                <div className='app-name'><Link to='/' style={{color:"#fff"}}>Cross Charge</Link></div>
               
                <div className='header-options'>

                    <RequestOptions 
                        setIsLoading = {setIsLoading}
                    />

                    <Dropdown
                        menu={{
                            items,
                        }}
                        placement="bottomRight"
                        className='cc-new-request-dropdown'
                        trigger={['click']}
                    >
                        <FontAwesomeIcon icon={faUser}
                            className='cc-user-icon'
                        />
                    </Dropdown>

                </div>
            </div>
        </div>
    )
}

export default Header