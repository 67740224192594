import React, { useEffect, useState } from 'react'
import { Steps, Tooltip } from 'antd';
import { ALL_AP_APPROVED, AWAITING_AP_REQUESTOR_SUBMISSION, AP_ATTACHMENT_POSTED, AP_CANCELLED, AP_DOCUMENT_POSTED, AP_DRAFT, AP_REJECTED, AR_ATTACHMENT_POSTED, AR_DOCUMENT_POSTED, AR_REJECTED, ATTACHMENT_POSTED_FAILED, AWAITING_AP_APPROVAL, AWAITING_AR_APPROVAL, AWAITING_AR_RESUBMISSION, CANCELLED, COMPLETED, DRAFT, range, AWAITING_AP_RESUBMISSION, formatTimezone, diffBtnDates, ABORTED, TIMED_OUT, FAILED, capitalize, ADMIN_REQUESTED_AR_RESUBMISSION, ADMIN_REQUESTED_AP_RESUBMISSION, ADMIN_REJECTED, AWAITING_FOR_AR_DOC_FAILED, AWAITING_FOR_AP_DOC_FAILED, AWAITING_FOR_ATTACHMENT_FAILED } from '../helper';

const Stages = ({reviewData}) => {

    const [step, setStep] = useState(-1);
    const [finalStepTitle, setFinalStepTitle] = useState('Complete');
    const [status, setStatus] = useState('');
    const [arStatus, setArStatus] = useState('');
    const [apStatus, setApStatus] = useState('');

    useEffect(()=>{
        reviewData && checkCurrentStep(reviewData)
    },[reviewData])

    const checkCurrentStep = (reviewData) => {

        if([ADMIN_REJECTED].includes(reviewData?.formStatus)){
            if((reviewData?.adminActionLvl_AR && reviewData?.adminActionLvl_AP) || reviewData?.adminActionLvl_AP){
                // setStep(parseInt(reviewData?.adminActionLvl_AR) + 1 + parseInt(reviewData?.adminActionLvl_AP))
                if(parseInt(reviewData?.adminActionLvl_AP) > reviewData?.totalAPApproverLVL){
                    setStep(reviewData?.totalARApproverLVL + 1 + reviewData?.totalAPApproverLVL + 1);
                    setStatus('error');
                    setFinalStepTitle(capitalize(ADMIN_REJECTED) );
                }else{
                    setStep(reviewData?.totalARApproverLVL + 1 + reviewData?.currAPApproverLVL)
                }
                
            }else{
                setStep(parseInt(reviewData?.adminActionLvl_AR))
            }
        }else if( [ABORTED, TIMED_OUT, FAILED].includes(reviewData?.formStatus)){
            setStatus('error');
            setFinalStepTitle(capitalize(reviewData?.formStatus));
        }else if([DRAFT, AWAITING_AR_RESUBMISSION, ADMIN_REQUESTED_AR_RESUBMISSION, CANCELLED].includes(reviewData?.formStatus)){

            setStep(0);
            if([CANCELLED].includes(reviewData?.formStatus)){
                setArStatus('error');
            }

        }else if([AWAITING_AR_APPROVAL, AR_REJECTED].includes(reviewData?.formStatus)){


            reviewData?.currARApproverLVL <= reviewData?.totalARApproverLVL ?
            setStep(reviewData?.currARApproverLVL) :
            setStep(reviewData?.totalARApproverLVL)

        }else if([AWAITING_AP_REQUESTOR_SUBMISSION, AP_DRAFT, AWAITING_AP_RESUBMISSION, AP_CANCELLED, ADMIN_REQUESTED_AP_RESUBMISSION].includes(reviewData?.formStatus)){

            setStep(reviewData?.totalARApproverLVL + 1);
            if([AP_CANCELLED].includes(reviewData?.formStatus)){
                setApStatus('error');
            }

        }else if([AWAITING_AP_APPROVAL, AP_REJECTED].includes(reviewData?.formStatus)){

            reviewData?.currAPApproverLVL <= reviewData?.totalAPApproverLVL ?
            setStep(reviewData?.totalARApproverLVL + 1 + reviewData?.currAPApproverLVL) :
            setStep(reviewData?.totalARApproverLVL + 1 + reviewData?.totalAPApproverLVL)

        }else if([AR_DOCUMENT_POSTED, ALL_AP_APPROVED, AP_DOCUMENT_POSTED, COMPLETED, ATTACHMENT_POSTED_FAILED, AR_ATTACHMENT_POSTED, AP_ATTACHMENT_POSTED, AWAITING_FOR_AR_DOC_FAILED, AWAITING_FOR_AP_DOC_FAILED, AWAITING_FOR_ATTACHMENT_FAILED].includes(reviewData?.formStatus)){

            setStep(reviewData?.totalARApproverLVL + 1 + reviewData?.totalAPApproverLVL + 1)

            if([COMPLETED].includes(reviewData?.formStatus)){
                setStatus('finish');
            }else if([ATTACHMENT_POSTED_FAILED, AWAITING_FOR_AR_DOC_FAILED, AWAITING_FOR_AP_DOC_FAILED, AWAITING_FOR_ATTACHMENT_FAILED].includes(reviewData?.formStatus)){
                setStatus('error');
                setFinalStepTitle(capitalize(reviewData?.formStatus))
            }

        }
    }

    
    const getArAppovalSteps = (reviewData)=>{
        const formLvl = reviewData?.arApprovers?.existARApprovers;
        return [...Array(reviewData?.totalARApproverLVL)].map((v,i)=>{

            const id = 'arapprover' + (i+1) + '_id'
            const doa = 'arapprover' + (i+1) + '_dateOfApproval'
            const approverStatus = 'arapprover'+ (i+1) + '_status'
            const approverLvl = 'arapprover'+ (i+1) + '_lvl'

            const previousApprover = 'arapprover'+ (i) + '_lvl'
            let previousApproverLvl = ''

            if( (i+1) === 1 ){
                previousApproverLvl = 1 ;
            }else{
                previousApproverLvl = reviewData?.arApprovers[previousApprover] + 1;
            }

            const minimum = Math.min(formLvl, reviewData?.arApprovers[approverLvl])
            const lvlArray = range(previousApproverLvl,minimum);

            // let dayPrevStep = 0;
            // const prevDoA = 'arapprover' + (i+1-1) + '_dateOfApproval'

            // if( (i+1) === 1 ){
            //     dayPrevStep = diffBtnDates(reviewData?.arRequestSubmittedDate, reviewData?.arApprovers[doa], 'days')
            // }else{
            //     dayPrevStep = diffBtnDates(reviewData?.arApprovers[prevDoA], reviewData?.arApprovers[doa], 'days')
            // }

            // console.log("diffBtnDates",diffBtnDates(reviewData?.arRequestSubmittedDate, reviewData?.arApprovers[doa], 'days') )

            if(parseInt(reviewData?.adminActionLvl_AR) <= parseInt(reviewData?.totalARApproverLVL)){
                if([ADMIN_REJECTED].includes(reviewData?.formStatus)){
                    if((i+1) === parseInt(reviewData?.adminActionLvl_AR)){
                        return {
                            title: <Tooltip title={reviewData?.adminsubmitedDate}>Admin Rejected</Tooltip>,
                            status:'error',
                            description: <>
                                    <Tooltip title={reviewData?.adminsubmitedDate}>
                                        <p >{reviewData?.adminUserId?.toUpperCase()}</p>
                                    </Tooltip>
                            </>,
                        }
                    }
                }
                
            }
            
            return {
                title: <Tooltip title={
                        <>{reviewData?.arApprovers[doa]}</>
                }>
                        AR Level {new Intl.ListFormat('en', { style: 'short', type: 'conjunction' }).format(lvlArray.map(String))}
                    </Tooltip>,

                status:(reviewData?.arApprovers[approverStatus] === AR_REJECTED) ? 'error' : '',

                description: <>
                    <Tooltip title={reviewData?.arApprovers[doa]}>
                        <p >{reviewData?.arApprovers[id]}</p>
                    </Tooltip>
                    {
                        ((i+1) === parseInt(reviewData?.adminActionLvl_AR)) &&
                        <div className='cc-roadmap-note'>
                        <Tooltip title="Admin has taken action at this step. Please check audit log for more details">
                            *
                        </Tooltip>
                        </div>
                    }
                </>,

            }
        })
    }

    const getApAppovalSteps = (reviewData)=>{
        const formLvl = reviewData?.apApprovers?.existAPApprovers;
        return [...Array(reviewData?.totalAPApproverLVL)].map((v,i)=>{
            
            const id = 'apapprover' + (i+1) + '_id'
            const doa = 'apapprover' + (i+1) + '_dateOfApproval'
            const approverStatus = 'apapprover'+ (i+1) + '_status'
            const approverLvl = 'apapprover'+ (i+1) + '_lvl'

            const previousApprover = 'apapprover'+ (i) + '_lvl'
            let previousApproverLvl = ''

            if( (i+1) === 1 ){
                previousApproverLvl = 1 ;
            }else{
                previousApproverLvl = reviewData?.apApprovers[previousApprover] + 1;
            }

            const minimum = Math.min(formLvl, reviewData?.apApprovers[approverLvl])
            const lvlArray = range(previousApproverLvl,minimum);

            if(parseInt(reviewData?.adminActionLvl_AP) <= parseInt(reviewData?.totalAPApproverLVL)){
                if([ADMIN_REJECTED].includes(reviewData?.formStatus)){
                    if((i+1) === parseInt(reviewData?.adminActionLvl_AP)){
                        return {
                            title: <Tooltip title={reviewData?.adminsubmitedDate}>Admin Rejected</Tooltip>,
                            status:'error',
                            description: <>
                                    <Tooltip title={reviewData?.adminsubmitedDate}>
                                        <p >{reviewData?.adminUserId?.toUpperCase()}</p>
                                    </Tooltip>
                            </>,
                        }
                    }
                }
                
            }

            return {
                title: <Tooltip title={reviewData?.apApprovers[doa]}>
                        AP Level {new Intl.ListFormat('en', { style: 'short', type: 'conjunction' }).format(lvlArray.map(String))}
                    </Tooltip>,
                status:(reviewData?.apApprovers[approverStatus] === AP_REJECTED) ? 'error' : '',
                description: <>
                    <Tooltip title={reviewData?.apApprovers[doa]}>
                        <p>{reviewData?.apApprovers[id]}</p>
                    </Tooltip>
                    {
                        ((i+1) === parseInt(reviewData?.adminActionLvl_AP)) &&
                        <div className='cc-roadmap-note'>
                        <Tooltip title="Admin has taken action at this step. Please check audit log for more details">
                            *
                        </Tooltip>
                        </div>
                    }
                </>,
            }
        })
    }

    const getApStep = (reviewData) =>{
        if([ADMIN_REJECTED].includes(reviewData?.formStatus) && ( parseInt(reviewData?.adminActionLvl_AR) > parseInt(reviewData?.totalARApproverLVL) )){
            return {
                title: <Tooltip title={reviewData?.adminsubmitedDate}>Admin Rejected</Tooltip>,
                status:'error',
                description: <>
                        <Tooltip title={reviewData?.adminsubmitedDate}>
                                <p >{reviewData?.adminUserId?.toUpperCase()}</p>
                        </Tooltip>
                </>,
            }
        }
        return {
            title: <Tooltip title={reviewData?.apRequestSubmittedDate}>AP Requestor</Tooltip>,
            status: apStatus,
            description: <>
                <Tooltip title={reviewData?.apRequestSubmittedDate}>
                    <p >{reviewData?.apRequestor}</p>
                </Tooltip>
                {
                    ( parseInt(reviewData?.adminActionLvl_AR) > parseInt(reviewData?.totalARApproverLVL) ) &&
                    <div className='cc-roadmap-note'>
                    <Tooltip title="Admin has taken action at this step. Please check audit log for more details">
                        *
                    </Tooltip>
                    </div>
                }
                </>
        }
    }

    const stages = [
        {
            title: <Tooltip title={reviewData?.arRequestSubmittedDate}>
                        Request Submitted
                    </Tooltip>,
            status: arStatus,
            description: <>
                <Tooltip title={reviewData?.arRequestSubmittedDate}>
                    <p>{reviewData?.arRequestor}</p>
                </Tooltip>
            </>
        },

        ...( reviewData?.totalARApproverLVL ? getArAppovalSteps(reviewData) : [{title:"AR Level 1"}] ),

        // {
        //     title: <Tooltip title={reviewData?.apRequestSubmittedDate}>AP Requestor</Tooltip>,
        //     status: apStatus,
        //     description: <>
        //         <Tooltip title={reviewData?.apRequestSubmittedDate}>
        //             <p >{reviewData?.apRequestor}</p>
        //         </Tooltip>
        //         </>
        // },
        getApStep(reviewData),

        ...( reviewData?.totalAPApproverLVL ? getApAppovalSteps(reviewData) : [{title:"AP Level 1"}] ),

        {
            title: <Tooltip title={formatTimezone(reviewData?.requestCompletionDate)}>
                        Request {finalStepTitle}
                    </Tooltip>,
            status:status
        }

    ]




    

    return (
        <div className='stage-parent'>
            <div className='stage-cnt'>
                <Steps
                    current={step}
                    items={stages}
                    size="small"
                    labelPlacement='vertical'
                    className='cc-stage'
                />
            </div>
        </div>
    )
}

export default Stages