import React from 'react'
import CardTemplate from './CardTemplate'
import ReceivingCmpnyInfo from './ReceivingCmpnyInfo'
import RequestInfo from './RequestInfo'
import SendingCmpnyInfo from './SendingCmpnyInfo'

const CardContainer = (props) => {
    return (
        <div className='cc-card-cnt'>
            <div className="row">

                <CardTemplate 
                    heading = 'Request Information'
                    color='#C7579A'
                >
                    <RequestInfo 
                        reviewData = {props.reviewData}
                    />
                </CardTemplate>

                <CardTemplate 
                    heading = 'Sending Company Information'
                    color='#ED8B00'
                >
                    <SendingCmpnyInfo 
                        reviewData = {props.reviewData}
                    />
                </CardTemplate>

                <CardTemplate 
                    heading = 'Receiving Company Information'
                    color='#3EB1C8'
                >
                    <ReceivingCmpnyInfo 
                        reviewData = {props.reviewData}
                    />
                </CardTemplate>

            </div>
        </div>

    )
}

export default CardContainer