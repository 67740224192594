import React from 'react';
import { Modal } from 'antd';
import { excelErrMsgs, excelWrongFormatErr } from '../helper';


const ExcelErrors = ({ errorMsg }) => {
    const showError = () => {
        const errTitle = "Excel Data Validation Error";
        const finalErrMsg = errorMsg ? excelWrongFormatErr : excelErrMsgs;

        Modal.error({
            className: 'info-modal',
            title: (<h3>{errTitle}</h3>),
            content:
                (<>
                    <h5>Please check the below points:</h5>
                    <ul>
                        {finalErrMsg?.map((item) => (<li className='info-modal-li'>{item}</li>))}
                    </ul>
                </>),
            okButtonProps: { "className": "modal-btn" },
            onOk: () => {
                Modal.destroyAll();
            }
        });
    };

    return (
        <>
            {showError()}
        </>
    )
}

export default ExcelErrors;