import React, { useContext } from 'react';
import { Dropdown, message } from 'antd';
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import ContextData from '../context/ContextData';
import { createCrossChargerequest } from '../../graphql/mutations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListUl, faHouse, faFile, faUpload } from '@fortawesome/free-solid-svg-icons'
import { formatDate, getDownloadTemplate } from '../helper';
import {AreaChartOutlined} from '@ant-design/icons'

const RequestOptions = (props) => {
    const apTempFileName = "templates/AP_Excel_Upload_Template.xlsx";
    const arTempFileName = "templates/AR_Excel_Upload_Template.xlsx";

    const { setIsLoading } = props;


    const navigate = useNavigate();

    const contextValue = useContext(ContextData);
    const { currentUser } = contextValue;

    const [messageApi, contextHolder] = message.useMessage();

    const createExecution = () => {

        const createInput = {
            actionType: "create",
            id: '123',
            arRequestor: currentUser?.userID?.toUpperCase(),
            arRequestorEmailAddress: currentUser?.userEmail,
            requestDate: formatDate()
        }

        setIsLoading(true);

        API
            .graphql({ query: createCrossChargerequest, variables: { input: createInput } })
            .then((response) => {
                console.log("create response", response);
                navigate(`/request/${response?.data?.createCrossChargerequest?.id}`);
                setIsLoading(false);
            }).catch((err) => {
                console.log("create err", err);
                setIsLoading(false);
                messageApi.open({
                    type: 'error',
                    content: 'This is an error while creating new request.',
                    className: 'message-style',
                    duration: 5
                });
            })
    }


    const items = [
        {
            key: 'home',
            label: (
                <div onClick={()=> navigate('/')} className='new-req-option'>
                    Home
                    <FontAwesomeIcon icon={faHouse}
                        title='Go to home'
                        className='cc-icons cc-icons-grey'
                        style={{ marginLeft: "10px" }}
                    />
                </div>
            ),
        },
        {
            key: 'start new',
            label: (
                <div onClick={createExecution} className='new-req-option'>
                    Start New
                    <FontAwesomeIcon icon={faFile}
                        title='Start New Request'
                        className='cc-icons cc-icons-grey'
                        style={{ marginLeft: "10px" }}
                    />
                </div>
            ),
        },
        {
            key: 'excel download ar',
            label: (
                <div onClick={()=> getDownloadTemplate(arTempFileName, messageApi)} className='new-req-option'>
                    Download Template (AR)
                    <FontAwesomeIcon icon={faUpload}
                        title='Download Excel (AR)'
                        className='cc-icons cc-icons-trans cc-icons-grey'
                        style={{ marginLeft: "10px" }}
                    />
                </div>
            ),
        },
        {
            key: 'excel download ap',
            label: (
                <div onClick={()=> getDownloadTemplate(apTempFileName, messageApi)} className='new-req-option'>
                    Download Template (AP)
                    <FontAwesomeIcon icon={faUpload}
                        title='Download Excel (AP)'
                        className='cc-icons cc-icons-trans cc-icons-grey'
                        style={{ marginLeft: "10px" }}
                    />
                </div>
            ),
        },
        {
            key: 'excel upload',
            label: (
                <div onClick={()=> navigate('/ar/excelupload')} className='new-req-option'>
                    Upload excel (AR)
                    <FontAwesomeIcon icon={faUpload}
                        title='Upload Excel (AR)'
                        className='cc-icons cc-icons-grey'
                        style={{ marginLeft: "10px" }}
                    />
                </div>
            ),
        },
        {
            key: 'excel upload ap',
            label: (
                <div onClick={()=> navigate('/ap/excelupload')} className='new-req-option'>
                    Upload excel (AP)
                    <FontAwesomeIcon icon={faUpload}
                        title='Upload Excel (AP)'
                        className='cc-icons cc-icons-grey'
                        style={{ marginLeft: "10px" }}
                    />
                </div>
            ),
        },
        {
            key: 'report',
            label: (
                <div onClick={()=> navigate('/Reports')} className='new-req-option'>
                    Report
                    <AreaChartOutlined
                        title='Go to report'
                        className='cc-icons cc-icons-grey'
                        style={{ marginLeft: "10px" }}
                    />
                </div>
            ),
        },
        
    ]


    return (
        <>
            {contextHolder}

            <Dropdown
                menu={{
                    items,
                }}
                placement="bottomRight"
                trigger={['click']}
                // className='cc-new-request-dropdown'
            >
                <FontAwesomeIcon icon={faListUl}
                    className='cc-user-icon'
                />
            </Dropdown>

        </>


    )
}

export default RequestOptions