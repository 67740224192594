import { Modal } from "antd";
import { API, Auth } from "aws-amplify";
import moment from "moment";
import momentTimeZone from 'moment-timezone';

// used as a separator btn value and description 
export const descSeparator = " - "

export const dashboardPollingInterval = 5000
export const dashboardRetrivingLimit = 500
export const cancelTimerInterval = 2000

// form statuses 
export const DRAFT = "DRAFT"
export const AWAITING_AR_RESUBMISSION = "AWAITING_AR_RESUBMISSION"
export const AWAITING_AR_APPROVAL = "AWAITING_AR_APPROVAL"
// export const ALL_AR_APPROVED = "ALL_AR_APPROVED"
export const AR_REQUESTED_INFORMATION = "AR_REQUESTED_INFORMATION"
export const AR_REJECTED = "AR_REJECTED"
export const CANCELLED = "CANCELLED"

export const AWAITING_AP_REQUESTOR_SUBMISSION = "AWAITING_AP_REQUESTOR_SUBMISSION"
export const AP_DRAFT = "AP_DRAFT"
export const AWAITING_AP_RESUBMISSION = "AWAITING_AP_RESUBMISSION"
export const AWAITING_AP_APPROVAL = "AWAITING_AP_APPROVAL"
export const AP_APPROVED = "AP_APPROVED"
export const ALL_AP_APPROVED = "ALL_AP_APPROVED"
export const AP_REQUESTED_INFORMATION = "AP_REQUESTED_INFORMATION"
export const AP_REJECTED = "AP_REJECTED"
export const AP_CANCELLED = "AP_CANCELLED"
export const AP_SUBMITTED = "AP_SUBMITTED"

export const COMPLETED = "COMPLETED"
export const AR_DOCUMENT_POSTED = "AR_DOCUMENT_POSTED"
export const AP_DOCUMENT_POSTED = "AP_DOCUMENT_POSTED"
export const AR_ATTACHMENT_POSTED = "AR_ATTACHMENT_POSTED"
export const AP_ATTACHMENT_POSTED = "AP_ATTACHMENT_POSTED"
export const ATTACHMENT_POSTED_FAILED = "ATTACHMENT_POSTED_FAILED"
export const ABORTED = "ABORTED"
export const TIMED_OUT = "TIMED_OUT"
export const FAILED = "FAILED"

export const ADMIN_REJECTED = "ADMIN_REJECTED"
export const ADMIN_REQUESTED_AP_RESUBMISSION = "ADMIN_REQUESTED_AP_RESUBMISSION"
export const ADMIN_REQUESTED_AR_RESUBMISSION = "ADMIN_REQUESTED_AR_RESUBMISSION"

export const AWAITING_FOR_AR_DOC_FAILED = "AWAITING_FOR_AR_DOC_FAILED"
export const AWAITING_FOR_AP_DOC_FAILED = "AWAITING_FOR_AP_DOC_FAILED"
export const AWAITING_FOR_ATTACHMENT_FAILED = "AWAITING_FOR_ATTACHMENT_FAILED"

export const TBP = 'To Be Populated'
export const BusinessCC = ['2003', '4003']
export const errorMsgSeparator = '<BR>'
export const arPostingKeys = ['01', '11']
export const apPostingKeys = ['21', '31']

// Admin actions 
export const Resend = 'Resend'
export const AdminReject = 'Admin Reject'
export const AdminRejectEnd = 'Admin Reject End'

// excel statuses 
export const Processed = 'Request Created'
export const Updated = 'Request Updated'
export const Failed = 'Failed'

export const currWithZeroDecimals = ["TRL", "GRD", "MZM", "LAK", "RWF", "PTE", "TMM", "TPE", "BEF", "ADP", "KMF", "CLP", "BIF", "KRW", "JPY", "DJF", "COP", "TWD", "BYR", "ITL", "VND", "TJR", "XPF", "XOF", "ESP", "HUF", "VUV", "MGF", "ECS", "AFA", "ROL", "BYB", "IDR", "GNF", "XAF", "PYG", "UGX", "LUF"];
export const excelWrongFormatErr = ["The excel sheet have wrong format. Please use the templete file from the website."];
export const excelErrMsgs = [
    "Amount upto 2 decimals places allowed.",
    "Amount of 0 (zero) is not allowed.",
    "Amount to be entered without seperators i.e. without , or . or spaces.",
    "Some currencies do not allow decimal values."
]

// used to adding to 3 digit number 
export const addPaddingToNum = (number, digit) => {
    if (number) {
        return (parseInt(number)).toLocaleString('en-US', { minimumIntegerDigits: digit, useGrouping: false })
    } else {
        return ''
    }
}


// used for deleting selected rows 
const reNumberItems = (items, setItems) => {
    let count = 0;
    const newItems = items.map(singleItem => {
        count++
        const itemNum = addPaddingToNum(count, 3)
        return { ...singleItem, item: itemNum, id: itemNum }
    })
    console.log("newItems", newItems);
    setItems(newItems);
}

export const DeleteItems = (ARItems, selectedRowKeys, setItems) => {
    const result = ARItems?.filter(md =>
        selectedRowKeys?.every(fd => fd !== md.item));

    reNumberItems(result, setItems);

}

// used to delete supporting docs row 
const reNumberDocs = (items, setItems) => {
    let count = 0;
    const newItems = items.map(singleItem => {
        count++
        return { ...singleItem, id: count, index: count }
    })
    setItems(newItems);
}

export const DeleteDocs = (docsList, selectedRowKeys, setDocs) => {
    const result = docsList?.filter(md =>
        selectedRowKeys?.every(fd => fd !== md.fileName));

    reNumberDocs(result, setDocs);

}

// used to group and return all obj items 
export const groupByKeyReturnObj = (arr, key) => {
    return arr.reduce((acc, cur) => {
        if (cur[key]) {
            acc[cur[key]] = [...acc[cur[key]] || [], cur];
        }
        return acc;
    }, {});
}

// used to group AR amounts and AP amounts accr to posting key 
export const groupByKey = (arr, key) => {
    return arr.reduce((acc, cur) => {
        acc[cur[key]] = [...acc[cur[key]] || [], parseFloat(cur['amount'])];
        return acc;
    }, {});
}

// to get the sum of elements of an array 
export const sum = (array) => {
    if (array && array.length > 0) {
        return array?.reduce((total, num) => total + num)
    } else {
        return 0
    }

}

// to capitalize words 
export const capitalize = (word) => {
    if (word) {
        // const capWord = word[0].toUpperCase() + word.slice(1).toLowerCase();
        // return capWord?.split('_')?.join(' ')
        const capWord = word?.split('_');
        for (var i = 0; i < capWord.length; i++) {
            if (!['AR', 'AP'].includes(capWord[i])) {
                capWord[i] = capWord[i].charAt(0).toUpperCase() + capWord[i].substr(1).toLowerCase();
            }
        }
        return capWord?.join(' ')
    }
}

// to validate difference of amounts of "(01 or 11) and (40 & 50) of AR" or "(21 or 31) and (40 & 50) of AP" 
export const checkAmount = (amntObj, firstItem) => {
    const remainingSum = sum(amntObj['40']) - sum(amntObj['50'])
    if (Math.abs(sum(amntObj[firstItem])) === Math.abs(remainingSum)) {
        return true
    } else {
        return false
    }
}

// splits string with respect to pipe (|) and returns aray 
export const splitWithPipe = (str) => {
    return str.split("|")
}

// condition to disable form based on the form status
export const formDisable = (status) => {
    return ![DRAFT, AWAITING_AR_RESUBMISSION, ADMIN_REQUESTED_AR_RESUBMISSION].includes(status?.toUpperCase());
}

export const allowAR = (status, arRequestor, currentUser) => {
    return [DRAFT, AWAITING_AR_RESUBMISSION, ADMIN_REQUESTED_AR_RESUBMISSION].includes(status?.toUpperCase()) && arRequestor?.toUpperCase() === currentUser?.toUpperCase()
}

export const enableEditForAp = (status, apRequestor, currentUser) => {
    return ([AWAITING_AP_REQUESTOR_SUBMISSION, AP_DRAFT, AWAITING_AP_RESUBMISSION, ADMIN_REQUESTED_AP_RESUBMISSION].includes(status?.toUpperCase()) && apRequestor?.toUpperCase() === currentUser?.toUpperCase())
}

export const allowAdmin = (status, isAdmin) => {
    return ([AWAITING_AP_REQUESTOR_SUBMISSION, AP_DRAFT, AWAITING_AP_RESUBMISSION, ADMIN_REQUESTED_AP_RESUBMISSION].includes(status?.toUpperCase()) && isAdmin)
}

// date format 
export const formatDate = (date) => {
    if (date) {
        return moment(date).format('MM/DD/YYYY')
    } else {
        return moment().format('MM/DD/YYYY')
    }
}

export const formatTimezone = (date) => {
    if (date) {
        return momentTimeZone(date).tz('America/New_York').format('MM/DD/YYYY hh:mm A z')
    } else {
        return momentTimeZone().tz('America/New_York').format('MM/DD/YYYY hh:mm A z')
    }

}

export const timezoneFormatString = 'MM/DD/YYYY hh:mm A z'

// find diff btn two dates 
export const diffBtnDates = (pastDate, presentDate, diffType) => {
    if (pastDate && presentDate && diffType) {
        const difference = moment(pastDate, timezoneFormatString).diff(moment(presentDate, timezoneFormatString), diffType)
        if (difference === 0) {
            return 1
        } else {
            return difference
        }
    } else {
        return ''
    }
}

// download file from a url 
export const downloadFromURL = (url, fileName, fileExtension) => {
    const a = document.createElement('a');
    if (fileExtension.toLowerCase() === 'pdf') {
        a.target = '_blank'
    }
    a.href = url;
    a.download = fileName || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}

// api call to get download url of the attachment 
export const getDownloadPresignedUrl = async (formID, fileName, messageApi) => {
    const key = 'downloadable';
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const fileExtension = fileName?.split(".")?.pop();

    messageApi.open({
        key,
        type: 'loading',
        content: 'Downloading file...',
    });

    API
        .post('CrossChargePresignedDownloadURL', '', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: { fileName: `${formID}/${fileName}` }
        }).then((response) => {
            if (response) {

                downloadFromURL(response, fileName, fileExtension);

                messageApi.open({
                    key,
                    type: 'success',
                    content: 'File downloaded successfully!',
                    duration: 2,
                })


            } else {
                messageApi.open({
                    key,
                    type: 'error',
                    content: 'There is an error while downloading the file.',
                    className: 'message-style',
                    duration: 5
                });
            }

        }).catch((err) => {
            console.log("presigned dnld err", err);
            messageApi.open({
                key,
                type: 'error',
                content: 'There is an error while getting presigned token and downloading the file.',
                className: 'message-style',
                duration: 5
            });
        })
}

// api call to download ap-ar template files 
export const getDownloadTemplate = async (fileName, messageApi) => {
    const key = 'downloadable';
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const fileExtension = fileName?.split(".")?.pop();

    messageApi.open({
        key,
        type: 'loading',
        content: 'Downloading file...',
    });

    API
        .post('CrossChargePresignedDownloadURL', '', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: { fileName: `${fileName}` }
        })
        .then((response) => {
            if (response) {

                downloadFromURL(response, fileName, fileExtension);

                messageApi.open({
                    key,
                    type: 'success',
                    content: 'File downloaded successfully!',
                    duration: 2,
                })


            } else {
                messageApi.open({
                    key,
                    type: 'error',
                    content: 'There is an error while downloading the file.',
                    className: 'message-style',
                    duration: 5
                });
            }

        }).catch((err) => {
            console.log("Download ArAp template excel err", err);
            messageApi.open({
                key,
                type: 'error',
                content: 'There is an error while while downloading the ap-ar template file.',
                className: 'message-style',
                duration: 5
            });
        })
}

// to get first line posting key 
export const checkRequstTypeAndAddPostingKey = (requestType, currentUser) => {
    if (requestType === 'invoice') {
        if (currentUser && currentUser === 'ar') {
            return [{
                value: '01',
                label: '01' + descSeparator + 'Invoice',
            }]
        } else if (currentUser && currentUser === 'ap') {
            return [{
                value: '31',
                label: '31' + descSeparator + 'Invoice',
            }]
        }

    } else if (requestType === 'creditNote') {
        if (currentUser && currentUser === 'ar') {
            return [{
                value: '11',
                label: '11' + descSeparator + 'Credit memo',
            }]
        } else if (currentUser && currentUser === 'ap') {
            return [{
                value: '21',
                label: '21' + descSeparator + 'Credit memo',
            }]
        }

    }
}


// to check first line posting key already exists or not 
export const isPostingKeyExist = (tableItems) => {
    const postingKey = tableItems && tableItems?.map(item => item?.postingKey);
    if (postingKey?.includes('01') || postingKey?.includes('11') || postingKey?.includes('21') || postingKey?.includes('31')) {
        return true
    } else {
        return false
    }
}

export const validatePostingKey = (items, reqType, itemType) => {
    const pstngKeyList = items?.map(i => i.postingKey);
    if (reqType === 'invoice') {
        if (itemType === 'ar') {
            return pstngKeyList?.includes('01')
        } else if (itemType === 'ap') {
            return pstngKeyList?.includes('31')
        }
    } else if (reqType === 'creditNote') {
        if (itemType === 'ar') {
            return pstngKeyList?.includes('11')
        } else if (itemType === 'ap') {
            return pstngKeyList?.includes('21')
        }
    }
}


export const getGeneralPostingKeys = () => {
    return ([
        {
            value: '40',
            label: '40' + descSeparator + 'Debit entry',
        },
        {
            value: '50',
            label: '50' + descSeparator + 'Credit entry',
        }
    ])
}

// get range of values 
export const range = (start, end) => {
    var myArray = [];
    if (parseInt(start) > parseInt(end)) {
        myArray.push(parseInt(end));
    } else {
        for (var i = parseInt(start); i <= parseInt(end); i += 1) {
            myArray.push(i);
        }
    }
    return myArray;

};

// to get count of the decimal places 
export const getDecimalCount = (value) => {
    if (value && Math.floor(value) === value) return 0;
    return value?.toString()?.split(".")[1]?.length || 0;
}

//to format numbers to 2 decimal number
export const formatNum = (num) => {

    if (getDecimalCount(num) > 0) {
        return num && parseFloat(num)?.toFixed(2)
    } else {
        return num
    }
}

// to check acc number starts with alphabet and if no adding leading zeroes to acc num 
export const checkAndPadd = (number, digit) => {
    const str = String(number)?.trim();

    if (str) {
        const regex = /^[a-z]/i
        if (!regex.test(str)) {
            return addPaddingToNum(str, digit)
        } else {
            return str
        }
    } else {
        return ''
    }

}

// to show error msg in modal 
export const showErrorMsg = (msg) => {
    Modal.error({
        className: 'info-modal',
        title: (
            <>
                <h3>Error:</h3>
                <ul>
                    {msg && msg?.split(errorMsgSeparator)?.map(itm => itm?.trim() && <li>{itm}</li>)}
                </ul>
            </>
        ),
        okButtonProps: { "className": "modal-btn" }
    })
}

// to get pagination options 
export const getPaginationOptions = (itemsLength, sizeLimit) => {
    return itemsLength > sizeLimit ? { size: 'small', showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100] } : false
}